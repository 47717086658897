import { loseDataWarning } from 'common/messaging'
import { observeEquipment, useCurrentUser, useMaintenance, useUndoRedo } from 'hooks'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DeckRevisionHistory } from 'models'
import { useNotification } from 'components/snackbar/NotificationProvider'
import { addWearAppAction, isPanelTheSame } from 'pages/common/maintenance/logic'
import BigNumber from 'bignumber.js'
import { parsedRevisionNumber } from 'common/revisionNumber'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { SCOPES } from 'components/auth/Scopes'

const defaultString = ``
const customerString = 'customer'
const siteString = 'site'
const locationString = 'location'
const equipmentString = 'equipment'
const screenString = 'screen'
const deckString = 'deck'
const deckRevisionString = 'deckRevision'
const editedDeckRevisionString = 'editedDeckRevision'

export const ONE_WEEK_MILLIS = 7 * 24 * 60 * 60 * 1000

export const defaultMaintenanceData = {
  deckRevisionHistory: new DeckRevisionHistory({}),
  photos: []
}

export const CurrentDeckContext = React.createContext({})

export const useCurrentDeckContext = () => useContext(CurrentDeckContext)
/*
 *The reference data for current Deck choice. This is saved locally and persists across all pages.
 *The History hook is also handled here.
 */
export function CurrentDeckProvider({ database, bottomNavDimen, isDesktop, children }) {
  const [customerId, saveCustomerId] = useState(localStorage.getItem(customerString) ?? defaultString)
  const [siteId, saveSiteId] = useState(localStorage.getItem(siteString) ?? defaultString)
  const [locationId, saveLocationId] = useState(localStorage.getItem(locationString) ?? defaultString)
  const [equipmentId, saveEquipmentId] = useState(localStorage.getItem(equipmentString) ?? defaultString)
  const [screenId, saveScreenId] = useState(localStorage.getItem(screenString) ?? defaultString)
  const [deckId, saveDeckId] = useState(localStorage.getItem(deckString) ?? defaultString)
  const [deckRevisionId, saveDeckRevisionId] = useState(localStorage.getItem(deckRevisionString) ?? defaultString)
  const [selectedPanel, setSelectedPanel] = useState(JSON.parse(localStorage.getItem('selectedPanel') ?? '[]'))
  const [deckRevision, saveDeckRevision] = useState(getSavedDeckRevision())
  useEffect(() => {
    localStorage.setItem('selectedPanel', JSON.stringify(selectedPanel))
  }, [selectedPanel])
  const [inMaintenanceMode, setInMaintenanceMode] = useState(false)
  const [maintenanceData, setMaintenanceData] = useState(defaultMaintenanceData)
  const [wearAnalysisResults, setWearAnalysisResults] = useState(
    JSON.parse(localStorage.getItem('wearAnalysisResults') ?? '{}')
  )
  const { getAction, actions } = useMaintenance()

  const equipments = observeEquipment()
  const { currentUser } = useCurrentUser()

  const maintenanceFrequency = useMemo(() => {
    return equipments.find(({ Name }) => Name === equipmentId)?.MaintenanceFrequency || 0
  }, [equipments, screenId])

  const deckRevisions = useMemo(() => {
    const unpublishedSites = currentUser.getSitesForScope(SCOPES.deckLayoutReview.Read)
    return database.deckRevisions
      .filter(
        (d) =>
          d.DeckId === deckId &&
          (currentUser.isAdmin || unpublishedSites.includes(d.SiteId) || (!d.IsTrial && d.IsPublished))
      )
      .sort((a, b) => new BigNumber(parsedRevisionNumber(b)).minus(parsedRevisionNumber(a)).toNumber())
  }, [database.deckRevisions, currentUser, deckId])

  // Get metenance histories
  const histories = useMemo(() => {
    const maintenanceSites = currentUser.getSitesForScope(SCOPES.deckMaintenance.Read)
    const histories = []
    deckRevisions.forEach((r) => {
      const revisionHistories = database.deckRevisionHistories
        .filter(
          (d) =>
            d.RevisionId === r.id &&
            d.HistoryType === 'Maintenance' &&
            (currentUser.isAdmin || maintenanceSites.includes(d.SiteId))
        )
        .sort((a, b) => (dayjs(b.DatePerformed).isAfter(dayjs(a.DatePerformed)) ? 1 : -1))
      revisionHistories.forEach((r) => {
        histories.push(r)
      })
    })
    return histories
  }, [deckRevisions, currentUser])

  const nonArchivedHistories = useMemo(() => {
    const revisions = database.deckRevisions
      .filter((d) => d.DeckId === deckId && !d.IsArchived)
      .sort((a, b) => new BigNumber(parsedRevisionNumber(b)).minus(parsedRevisionNumber(a)).toNumber())

    const maintenanceSites = currentUser.getSitesForScope(SCOPES.deckMaintenance.Read)
    const histories = []
    revisions.forEach((r) => {
      const revisionHistories = database.deckRevisionHistories
        .filter(
          (d) =>
            d.RevisionId === r.id &&
            d.HistoryType === 'Maintenance' &&
            (currentUser.isAdmin || maintenanceSites.includes(d.SiteId))
        )
        .sort((a, b) => (dayjs(b.DatePerformed).isAfter(dayjs(a.DatePerformed)) ? 1 : -1))
      revisionHistories.forEach((r) => {
        histories.push(r)
      })
    })

    return { histories, revisions }
  }, [database.deckRevisions, deckId, currentUser])

  const panelInstallDate = useMemo(() => {
    const panel = selectedPanel[0]
    const panelHistories = nonArchivedHistories.histories.filter((d) =>
      d.Details?.find((d) => isPanelTheSame(panel, d.Panel))
    )
    let panelToSearch = undefined
    for (const history of panelHistories) {
      for (const detail of history.Details) {
        if (!isPanelTheSame(detail.Panel, panel)) {
          continue
        }

        if (detail.HistoryAction === actions.Rotate.id) {
          continue
        }

        if (detail.HistoryAction === actions.Relocate.id || detail.HistoryAction === actions.Replace.id) {
          return new Date(history.DatePerformed).getTime()
        }
        if (detail.HistoryAction === actions.RelocateTo.id || detail.HistoryAction === actions.Swap.id) {
          panelToSearch = detail.SwappedPanel

          break
        }
      }
    }

    if (panelToSearch) {
      const panelToSearchHistory = deckRevisions.find(
        (revision) =>
          revision.Details?.find((d) => isPanelTheSame(panelToSearch, d.Panel)) &&
          (revision.HistoryAction === actions.Relocate.id || revision.HistoryAction === actions.Replace.id)
      )
      return new Date(panelToSearchHistory?.DatePerformed).getTime()
    }

    return Date.now() - ONE_WEEK_MILLIS
  }, [selectedPanel, deckRevisions, actions, nonArchivedHistories])

  const screen = useMemo(() => database.screens.find((s) => s.id === screenId), [screenId, database.screens])
  const deck = useMemo(() => database.decks.find((d) => d.id === deckId), [deckId, database.decks])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { notify } = useNotification()
  const panelQuery = useQuery(
    [`fetchPanel_${selectedPanel[0]?.MaterialNumber}`],
    () => {
      return fetch(`${process.env.REACT_APP_WEARAPP_REST_BACKEND_URL}?panelId=${selectedPanel[0].MaterialNumber}`).then(
        (val) => val.json()
      )
    },
    {
      enabled:
        inMaintenanceMode &&
        !!selectedPanel[0] &&
        (pathname === '/equipment/maintenance' || pathname === '/mobile/maintenance'),
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (Object.keys(wearAnalysisResults ?? {}).length > 0) {
      localStorage.setItem('wearAnalysisResults', JSON.stringify(wearAnalysisResults))
    }
  }, [wearAnalysisResults])

  useEffect(() => {
    if (!inMaintenanceMode) {
      localStorage.removeItem('wearAnalysisResults')
    }
  }, [inMaintenanceMode])

  useEffect(() => {
    return () => {
      localStorage.removeItem('wearAnalysisResults')
    }
  }, [])

  const onCompleteWearAppPress = (uuid, selectedPanel) => {
    notify('Saving wearapp data ...')

    const wearAppResults = wearAnalysisResults[uuid]
    const shouldReplacePanel = wearAppResults.replacePanel?.replacePanel === 'replace'

    const clone = addWearAppAction(
      wearAppResults,
      maintenanceData,
      selectedPanel,
      shouldReplacePanel ? actions.Replace.id : actions.NoChange.id,
      deckRevision,
      shouldReplacePanel ? dayjs().toISOString() : undefined
    )
    setMaintenanceData(clone)
    navigate(isDesktop ? '/equipment/maintenance' : '/mobile/maintenance')
    notify('Wearapp data saved!')
  }

  const memoizedDeckRevision = useMemo(() => {
    const revisions = database.deckRevisions
      .filter((d) => d.DeckId == deckId && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
    if (revisions.length) {
      const revision = structuredClone(revisions[0])
      return revision
    }
  }, [database.deckRevisions, deckId])

  const history = useUndoRedo()

  const address = useLocation()

  function getSavedDeckRevision() {
    let results = {}
    try {
      results = JSON.parse(localStorage.getItem(editedDeckRevisionString)) ?? {}
    } catch (err) {
      console.warn(err.message)
    }
    return results
  }

  function setCustomerId(id, checked = true) {
    if (id === customerId || checked || confirmChange()) {
      saveCustomerId(id)
      localStorage.setItem(customerString, id)
      history.clearHistory()
      return true
    }
    return false
  }

  function setSiteId(id, checked = true) {
    if (id === siteId || checked || confirmChange()) {
      saveSiteId(id)
      localStorage.setItem(siteString, id)
      history.clearHistory()
      return true
    }
    return false
  }

  function setLocationId(id, checked = true) {
    if (id === locationId || checked || confirmChange()) {
      saveLocationId(id)
      localStorage.setItem(locationString, id)
      history.clearHistory()
      return true
    }
    return false
  }

  function setEquipmentId(id, checked = true) {
    if (id === equipmentId || checked || confirmChange()) {
      saveEquipmentId(id)
      localStorage.setItem(equipmentString, id)
      history.clearHistory()
      return true
    }
    return false
  }

  function setScreenId(id, checked = true) {
    if (id === screenId || checked || confirmChange()) {
      saveScreenId(id)
      localStorage.setItem(screenString, id)
      history.clearHistory()
      return true
    }
    return false
  }

  function setDeckId(id, checked = true) {
    if (id === deckId || checked || confirmChange()) {
      saveDeckId(id)
      localStorage.setItem(deckString, id)
      history.clearHistory()

      const revisions = database.deckRevisions
        .filter((d) => d.DeckId === id && d.IsArchived !== true)
        .sort((a, b) => new BigNumber(parsedRevisionNumber(b)).minus(parsedRevisionNumber(a)).toNumber())
      if (revisions.length) {
        const revision = structuredClone(revisions[0])
        setDeckRevision(revision)

        const filteredRevisions = revisions.filter((r) => r.IsPublished)

        if (filteredRevisions.length) {
          const first = filteredRevisions[0]
          setDeckRevisionId(first.id)
        }
      }
      return true
    }
    return false
  }

  function setDeckRevisionId(id) {
    saveDeckRevisionId(id)
    localStorage.setItem(deckRevisionString, id)
  }

  function setDeckRevision(revision) {
    saveDeckRevision(revision)
    localStorage.setItem(editedDeckRevisionString, JSON.stringify(revision))
  }

  function isPathNameEligible() {
    const splitPath = address.pathname.toLowerCase().split('/')
    return splitPath.includes('equipment') && (splitPath.includes('edit') || splitPath.includes('maintenance'))
  }

  function confirmChange() {
    if (Object.entries(deckRevision).length && isPathNameEligible() && history.history.length > 0) {
      return window.confirm(loseDataWarning)
    }
    return true
  }

  const value = {
    database,
    customerId,
    setCustomerId,
    siteId,
    setSiteId,
    locationId,
    setLocationId,
    equipmentId,
    setEquipmentId,
    screenId,
    setScreenId,
    deckId,
    setDeckId,
    deckRevisionId,
    setDeckRevisionId,
    deckRevision,
    setDeckRevision,
    history,
    selectedPanel,
    setSelectedPanel,
    memoizedDeckRevision,
    screen,
    deck,
    inMaintenanceMode,
    maintenanceData,
    setInMaintenanceMode,
    setMaintenanceData,
    onCompleteWearAppPress,
    setWearAnalysisResults,
    bottomNavDimen,
    panelQuery,
    actions,
    getAction,
    wearAnalysisResults,
    isDesktop,
    histories,
    deckRevisions,
    maintenanceFrequency,
    panelInstallDate,
    nonArchivedHistories
  }

  return <CurrentDeckContext.Provider value={value}>{children}</CurrentDeckContext.Provider>
}
