/**
 *
 * "Welcome to the App file. The app workflow is following
 * 1. User authentication
 * 2. If logged in -> Page layout -> AllRoutes.js to render all pages of the app.
 * 3. Else -> Landing page"
 *
 * @file   PageLayout.js
 * @author Lateral
 * @since  2023
 */
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { DataStore, Auth, Hub } from 'aws-amplify'
import { Landing } from 'components'
import { SyncDatabaseProvider, UserProvider } from 'hooks'
import { Typography, Dialog, Stack, Button } from '@mui/material'
import { PageLayout } from 'pages'
import { LegalDialogue } from 'components/LegalDialogue'

function App() {
  /**
   * Main app funtion
   *
   * @function
   *
   * @returns {object} - If user axist start the App else return landing page.
   */
  const [user, setUser] = useState(null)

  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          refreshUser()
          break
        case 'signOut':
          setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data)
          break
        case 'tokenRefresh_failure':
          console.error('token refresh failed')
          DataStore.clear()
          Auth.signOut()
          break
      }
    })

    refreshUser()

    return () => {
      listener()
    }
  }, [])

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({ bypassCache: true })
      const idTokenPayload = (await Auth.currentSession()).getIdToken().decodePayload()
      return {
        ...userData,
        attributes: {
          ...userData.attributes,
          sites: idTokenPayload.sites,
          customerName: idTokenPayload.customerName
        }
      }
    } catch {
      console.log('Not signed in')
    }
  }

  async function refreshUser() {
    const userData = await getUser()
    if (userData) {
      setUser(userData)
    }
    return userData
  }

  const hasGroups = user?.signInUserSession.accessToken.payload['cognito:groups']?.length > 0
  const hasCustomerId = user?.attributes['customerName']?.length > 0
  const legalData = JSON.parse(user?.signInUserSession?.idToken?.payload['legalAcceptance'] ?? '{}')
  useLayoutEffect(() => {
    let markerApp
    const interval = setInterval(() => {
      markerApp = document.getElementsByClassName('marker-app')[0]
      if (!markerApp || !markerApp.firstChild || !markerApp.firstChild.style) {
        return
      }
      markerApp.firstChild.style.top = '10%'

      clearInterval(interval)
    }, 100)

    return () => clearInterval(interval)
  }, [])

  if (!user) {
    return <Landing />
  } else if (legalData.showLegalRequirementDialog) {
    return <LegalDialogue legalData={legalData} refreshUser={refreshUser} />
  } else if (!hasGroups || !hasCustomerId) {
    return (
      <Dialog open>
        <Stack spacing={2} sx={{ padding: '2em' }}>
          <Typography>You have no customers associated with your account.</Typography>
          <Typography>Please contact Support for more assistance.</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              Auth.signOut()
            }}>
            Logout
          </Button>
        </Stack>
      </Dialog>
    )
  }

  //if user exists (i.e., logged in) go to App. If not, go to Landing page.
  return (
    <UserProvider currentUser={user}>
      <SyncDatabaseProvider refreshUser={refreshUser}>
        <PageLayout />
      </SyncDatabaseProvider>
    </UserProvider>
  )
}

export default App
