import { Button, Stack } from '@mui/material'
import React from 'react'
import { useCSVDownloader, useCSVReader } from 'react-papaparse'
import BigNumber from 'bignumber.js'
import { useGetMaterials } from 'hooks/database/useGetMaterials'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMaterial, saveMaterial } from 'pages/admin/common/adminDatastore'
/*
 * The CSV reader/logic for Materials
 */
function MaterialCSVReader({ setTotal, setTotalNew, setTotalUpdated, setUploadStatus, uploadStatus }) {
  const { CSVReader } = useCSVReader()
  const { CSVDownloader } = useCSVDownloader()
  const queryClient = useQueryClient()
  const csvConfig = {
    header: true,
    skipEmptyLines: true
  }
  const materials = useGetMaterials()
  const updateMaterial_ = useMutation(['updateMaterial'], async ({ material, original }) => {
    await updateMaterial(original.id, {
      md: material.MaterialDescription?.length > 0 ? material.MaterialDescription : original.MaterialDescription,
      mn: material.MaterialNumber?.length > 0 ? material.MaterialNumber : original.MaterialNumber,
      pn: material.PartNumber?.length > 0 ? material.PartNumber : original.PartNumber,
      oa: material.OpenArea?.length > 0 ? material.OpenArea : original.OpenArea,
      wt: material.Weight?.length > 0 ? material.Weight : original.Weight,
      sd: material.SafetyDepth?.length > 0 ? material.SafetyDepth : original.SafetyDepth ?? null
    })
  })

  const saveMaterial_ = useMutation(['saveMaterial'], async (material) => {
    await saveMaterial({
      MaterialDescription: material.MaterialDescription,
      MaterialNumber: material.MaterialNumber,
      OpenArea: material.OpenArea,
      PartNumber: material.PartNumber,
      Weight: material.Weight,
      SafetyDepth: material.SafetyDepth
    })
  })

  async function importMaterials(results) {
    //filter empty rows
    const data = results.data.filter((d) => Object.values(d).every((x) => x === null || x === '') === false)
    setUploadStatus({ isLoading: true, csvSelected: true, hasFinished: false })
    setTotal(new BigNumber(data.length))
    await handleResults(data)
    setUploadStatus({ isLoading: false, csvSelected: true, hasFinished: true })
  }
  async function handleResults(data) {
    let newAmount = new BigNumber(0)
    let updatedAmount = new BigNumber(0)
    setTotalNew(newAmount)
    setTotalUpdated(updatedAmount)

    for (const result of data) {
      const index = materials.findIndex((m) => m.MaterialNumber === result.MaterialNumber)
      if (index !== -1) {
        await updateMaterial_.mutateAsync({ material: result, original: materials[index] })
        newAmount = newAmount.plus(1)
        setTotalNew(newAmount)
      } else {
        saveMaterial_.mutateAsync(result)
        updatedAmount = updatedAmount.plus(1)
        setTotalUpdated(updatedAmount)
      }
    }
    queryClient.refetchQueries(['fetchMaterials'])
    queryClient.resetQueries(['fetchMaterials'])
  }
  function getExampleCSV() {
    return [
      {
        PartNumber: '',
        MaterialDescription: '',
        MaterialNumber: '',
        OpenArea: '',
        Weight: '',
        SafetyDepth: ''
      }
    ]
  }
  return (
    <Stack direction="row" spacing={2}>
      <CSVReader config={csvConfig} onUploadAccepted={(results) => importMaterials(results)}>
        {({ getRootProps }) => (
          <Button
            {...getRootProps()}
            variant="outlined"
            sx={{ marginLeft: '0 !important' }}
            disabled={uploadStatus.isLoading}>
            Import Materials CSV
          </Button>
        )}
      </CSVReader>
      <CSVDownloader bom filename={'example-material-import'} data={getExampleCSV}>
        <Button variant="outlined">Download Example CSV</Button>
      </CSVDownloader>
    </Stack>
  )
}
export default MaterialCSVReader
