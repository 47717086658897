/**
 *
 * "Page to view and handle all user related events"
 *
 * @file   UsersPage.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import { Button, Typography, Stack, Box } from '@mui/material'
import { log } from 'common'
import { FormDialog, SCOPES, ScopeRequirementAll, useNotification } from 'components'
import UserItemForm from './components/users/UserItemForm'
import UserTable from './components/users/UserTable'
import {
  useAdminDatastore,
  createUser,
  adminUpdateUserAttributes,
  addUserToGroup,
  removeUserFromGroup,
  disableUser,
  enableUser
} from './common/adminDatastore'
import { observeRoles, observeCustomers, observeUserRoles, useCurrentUser } from 'hooks'
import { addOrUpdateUserRole, removeUserRole } from './common/data'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'

const UsersPage = () => {
  /**
   * Page to view and handle user realted events
   * @const
   *
   * @returns {object} - React app page
   */
  const { notify } = useNotification()
  const [userItemModalState, setUserItemModalState] = useState({ isOpen: false })
  const roles = observeRoles()
  const userRoles = observeUserRoles()
  const customers = observeCustomers()
  const { usersQuery } = useAdminDatastore()
  const { currentUser } = useCurrentUser()
  const groups = currentUser.getGroups()
  const writeableSites = currentUser.getSitesForScope(SCOPES.userManagement.Write)
  const writeableCustomers = currentUser.getCustomersForScope(SCOPES.userManagement.Write)
  const hasCognitoAdmin = groups.filter((g) => g.toLowerCase() === 'administrators').length > 0

  const [isSavingUser, setIsSavingUser] = useState(false)

  if (usersQuery.isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <ProgressIndicator />
      </Box>
    )
  if (usersQuery.error)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography>{'An error has occurred: ' + usersQuery.error.message}</Typography>
      </Box>
    )

  const users = usersQuery.data
  let userRows = users?.map((item) => {
    const companyIds = item?.Attributes?.find((x) => x.Name === 'customerName')?.Value
    const siteIds = item?.Attributes?.find((x) => x.Name === 'sites')?.Value
    let parsedCompanyIds = []
    try {
      const parsed = JSON.parse(companyIds)
      parsedCompanyIds = parsed
    } catch {
      parsedCompanyIds = [companyIds]
    }
    const parsedSiteIds = siteIds?.length ? JSON.parse(siteIds) : []
    const companyNames = customers.filter((c) => parsedCompanyIds.includes(c.id)).map((c) => c.Name)
    const ownUserRoles = userRoles.filter((u) => u.CognitoId === item?.Username).map((u) => u.RoleId)
    const ownRoles = roles.filter((r) => ownUserRoles.includes(r.id))
    return {
      Username: item?.Username,
      CompanyIds: parsedCompanyIds,
      SiteIds: parsedSiteIds,
      CompanyNames: companyNames,
      FirstName: item?.Attributes?.find((x) => x.Name === 'given_name')?.Value,
      LastName: item?.Attributes?.find((x) => x.Name === 'family_name')?.Value,
      Email: item?.Attributes?.find((x) => x.Name === 'email')?.Value,
      RoleIds: ownRoles.map((r) => r.id),
      Roles: ownRoles.map((r) => r.Name),
      Enabled: item?.Enabled
    }
  })

  let filteredRoles = roles

  if (!currentUser.isAdmin) {
    //only allow customers to see users and add/remove roles for their sites
    const siteIds = currentUser?.getSitesForScope(SCOPES.userManagement.Read)
    userRows = userRows.filter(
      (u) => u.CompanyIds.some((c) => currentUser.customerIds.includes(c)) && u.SiteIds.some((s) => siteIds.includes(s))
    )
    filteredRoles = roles.filter((r) => r.SiteIds?.some((s) => siteIds.includes(s)))
  }

  // Handle CRUD for roles
  const handleEvent = async (eventName, context) => {
    if (eventName === 'editUser') {
      context.id = context.Username
      setUserItemModalState({ isOpen: true, title: 'Edit User', buttonText: 'Update', data: context })
    } else if (eventName === 'disableUser') {
      try {
        await disableUser(context.Username)
        usersQuery.refetch()
      } catch (err) {
        notify('Failed to disable user')
      }
    } else if (eventName === 'enableUser') {
      try {
        await enableUser(context.Username)
        usersQuery.refetch()
      } catch (err) {
        notify('Failed to enable user')
      }
    }
  }

  // Modal for role CRUD
  const handleUserItemDialogClose = async (modalState) => {
    if (modalState?.isSave) {
      const data = modalState.data
      setIsSavingUser(true)

      try {
        if (data?.id) {
          notify(`Updating user ...`)

          const originalCompanies = data.OriginalCompanyNames
          const newCompanies = data.CompanyNames
          const companiesToRemove = originalCompanies.filter((company) => !newCompanies.includes(company))
          const originalRoles = roles.filter((r) => modalState.originalData.RoleIds.includes(r.id))
          const newRoles = roles.filter((r) => data.Roles.includes(r.id) && !companiesToRemove.includes(r.CustomerId))

          const originalGroups = originalRoles.map((r) => r.Group)
          const newGroups = newRoles.map((r) => r.Group)

          const groupsToRemove = originalGroups.filter((group) => !newGroups.includes(group))

          const groupsToAdd = newGroups.filter((group) => !originalGroups.includes(group))

          const newRoleIds = newRoles.map((r) => r.id)
          const rolesToRemove = originalRoles.filter((r) => !newRoleIds.includes(r.id))

          const sites = JSON.stringify([...new Set(newRoles.flatMap((r) => r.SiteIds).filter((s) => s))])
          const companyNames = JSON.stringify(newRoles.map((r) => r.CustomerId).filter((c) => c))

          for (const group of groupsToRemove) {
            await removeUserFromGroup(data.Username, group)
          }

          for (const group of groupsToAdd) {
            await addUserToGroup(data.Username, group)
          }

          for (const role of rolesToRemove) {
            await removeUserRole(data?.id, role.id)
          }

          for (const role of newRoles) {
            await addOrUpdateUserRole({ CognitoId: data?.id, CustomerId: role.CustomerId, RoleId: role.id })
          }

          await adminUpdateUserAttributes(data.Username, data.FirstName, data.LastName, companyNames, sites)

          notify(`User Item updated successfully`)
        } else {
          notify(`Adding user ...`)

          const newRoles = roles.filter((r) => data.Roles.includes(r.id))
          const sites = JSON.stringify([...new Set(newRoles.flatMap((r) => r.SiteIds))])
          const companyNames = JSON.stringify(data.CompanyNames)
          const user = await createUser(data.Email, data.FirstName, data.LastName, companyNames, sites)
          const usedGroups = []
          for (const role of newRoles) {
            if (role) {
              await addOrUpdateUserRole({
                CognitoId: user?.User.Username,
                CustomerId: role.CustomerId,
                RoleId: role.id
              })
              if (!usedGroups.includes(role.Group)) {
                await addUserToGroup(user.User.Username, role.Group)
                usedGroups.push(role.Group)
              }
            }
          }

          notify(`User Item Added`)
        }
      } catch (error) {
        log.error(`failed to save, error ${error}`)
        notify(`An error occurred whilst attempting to save`)
      } finally {
        usersQuery.refetch()
        setUserItemModalState({ isOpen: false })
      }
    }
    setUserItemModalState({ isOpen: false })

    setIsSavingUser(false)
  }

  return (
    <Stack spacing={2}>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Manage Users</Typography>
        {!hasCognitoAdmin && currentUser.hasAllScopes([SCOPES.userManagement.Write]) && (
          <Typography color="error">
            Your role has User write access, but does not have Admin Cognito permission. Adding/editing Users will be
            disabled.
          </Typography>
        )}
        {hasCognitoAdmin && (
          <ScopeRequirementAll requirements={[SCOPES.userManagement.Write]}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ justifySelf: 'flex-end', width: '155px', height: '32px', padding: 0 }}
              onClick={() => setUserItemModalState({ isOpen: true, title: 'Create New User', buttonText: 'Create' })}>
              Add User
            </Button>
          </ScopeRequirementAll>
        )}
      </Stack>
      <UserTable rows={userRows} hasCognitoAdmin={hasCognitoAdmin} handleEvent={handleEvent} />

      {userItemModalState.isOpen && (
        <FormDialog modalState={userItemModalState} onOpenChange={handleUserItemDialogClose} isLoading={isSavingUser}>
          <UserItemForm
            roleList={filteredRoles.filter(
              (r) => r.Enabled && (currentUser.isAdmin || r.SiteIds?.some((s) => writeableSites.includes(s)))
            )}
            companyList={customers.filter((c) => currentUser.isAdmin || writeableCustomers.includes(c.id))}
          />
        </FormDialog>
      )}
    </Stack>
  )
}
export default UsersPage
