/**
 *
 * "Provides the sideliners as group of 2D boxes in mobile display version
 *  calculated from deck revisions data from database"
 *
 * @file   mobileDeckCanvas\SideLiner.js
 * @author Lateral
 * @since  2023
 */

import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { getWearColour, pickHeatColour } from 'common/heatColours'
import { useMaintenance } from 'hooks'
import React from 'react'

/*
 *
 */
export function SideLiner({
  data,
  kits,
  maintenance,
  materialSelected,
  onPanelClick,
  selected,
  scale,
  side,
  mode,
  presentation,
  heatMapData
}) {
  /**
   * Generates a Sideliner boxes based on incoming deck rivion data.
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {object[]} kits - Item details including material details of panels and sideliners
   * @param {number} materialSelected - Selected sideliner material number
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} scale - Scale of the canvas
   * @param {string} side - Which side Left?Right.
   * @param {boolean} presentation - In maintenance mode presentation = True to show high contrast texts
   * @param {string} mode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {object} - React group of 2D boxes
   */

  const sideLinerItems = data.sort((a, b) => a.StartPosition - b.StartPosition)
  const left = side === 'left' ? '0.2em' : '1em'
  const right = side === 'right' ? '0' : '1em'
  const theme = useTheme()
  const { actions, getAction } = useMaintenance()
  const interactionColour = theme.palette.secondary.main
  const emptyColour = theme.palette.primary.dark

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ marginLeft: `${left}`, marginRight: `${right}`, height: 'fit-content', width: 'fit-content' }}>
      <Typography fontSize={12} sx={[{ width: '100%', textAlign: 'center' }, presentation ? { color: 'black' } : null]}>
        {side === 'left' ? 'RS' : 'LS'}
      </Typography>
      {sideLinerItems.map((item) => {
        const isMaterialSelected = materialSelected == item.MaterialNumber
        const isSelected =
          selected.filter(
            (s) =>
              s.MaterialNumber == item.MaterialNumber && s.StartPosition == item.StartPosition && s.Side == item.Side
          ).length > 0

        const kit = kits.find((k) => k.MaterialNumber === item.MaterialNumber)
        const height = `${new BigNumber(item.Height).multipliedBy(scale).toNumber()}em`
        const width = `${scale}em`

        let colour =
          isSelected || isMaterialSelected
            ? interactionColour
            : mode.includes(DeckMode.maintenance)
            ? emptyColour
            : kit?.Colour ?? theme.palette.primary.dark

        let maintenanceAction = null

        //if in Maintenance mode, change colours
        if (mode.includes(DeckMode.maintenance) && maintenance) {
          const startPosition = new BigNumber(item.StartPosition)
          const detail = maintenance.Details?.find(
            (d) => startPosition.isEqualTo(d.SideLiner?.StartPosition) && item.Side === d.SideLiner?.Side
          )

          if (detail) {
            maintenanceAction = getAction(detail.HistoryAction)

            if (
              (mode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
              (mode === DeckMode.preMaintenance &&
                (detail.WorkingDepth || detail.WorkingDepth === 0) &&
                (detail.ApertureWidth || detail.ApertureWidth === 0))
            ) {
              colour = getWearColour(detail, theme)
            } else if (mode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
              colour = 'white'
            }
          }
        } else if (mode === DeckMode.heatMap) {
          const sideLinerHeatData = heatMapData.SideLiners.find(
            (s) => s.Side === item.Side && s.StartPosition === item.StartPosition
          )

          const wearCount = new BigNumber(sideLinerHeatData.WearCount)
          const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
          const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
          colour = pickHeatColour(heatCount, theme)
        }
        return (
          <Box key={`${item.Side}-${item.StartPosition}`}>
            {maintenanceAction &&
            maintenanceAction.id !== actions.NoChange.id &&
            (mode === DeckMode.maintenance || mode === DeckMode.postMaintenance) ? (
              <img
                src={`${maintenanceAction.svg}`}
                style={{ border: '1px solid black', height: `${height}`, width: `${width}`, verticalAlign: 'top' }}
                onClick={(e) => {
                  e.stopPropagation()
                  onPanelClick(item)
                }}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: `${colour}`,
                  border: '1px solid black',
                  width: { width },
                  height: { height },
                  '&:hover': {
                    backgroundColor: interactionColour
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  onPanelClick(item)
                }}
              />
            )}
          </Box>
        )
      })}
    </Stack>
  )
}
