import { useRef, useCallback, useEffect, useLayoutEffect } from 'react'
import { debounce } from 'lodash'
import { fabric } from 'fabric'
import { useWearAppContext } from 'components/maintenanceSelector/wearapp/WearAppContext'

export const initFabric = (canvasEl) => {
  fabric.Object.prototype.selectable = false
  fabric.Object.prototype.hasControls = false
  fabric.Object.prototype.hasBorders = false
  fabric.Group.prototype.selectable = false
  fabric.Group.prototype.hasControls = false
  fabric.Group.prototype.hasBorders = false
  return new fabric.Canvas(canvasEl)
}

export const useInitFabric = ({ canvasId }) => {
  const { image, isFile } = useWearAppContext()
  const canvasRef = useRef()
  const imageRef = useRef()
  const containerRef = useRef(null)
  const loaded = useRef(false)
  const resizeCanvas = useCallback(() => {
    if (!canvasRef.current || !containerRef.current || !imageRef.current) {
      return
    }

    const image = imageRef.current
    const canvas = canvasRef.current
    canvas.setHeight(containerRef.current.clientHeight)
    canvas.setWidth(containerRef?.current.clientWidth)
    const scaleRatio = Math.min(
      containerRef.current.clientWidth / (image.width ?? 1),
      containerRef.current.clientHeight / (image.height ?? 1)
    )
    canvas.setZoom(scaleRatio)
    canvas.renderAll()
  }, [])

  useEffect(() => {
    const debounceResizer = debounce(resizeCanvas, 30)
    window.addEventListener('resize', debounceResizer)
    return () => window.removeEventListener('resize', debounceResizer)
  }, [resizeCanvas])

  useEffect(() => {
    return () => {
      canvasRef.current?.dispose()
    }
  }, [])

  const loadFile = useCallback(() => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (loaded.current) {
        return
      }
      const imageEl = new Image()
      imageEl.src = event.target.result
      imageEl.onload = () => {
        if (loaded.current) {
          return
        }
        const image = new fabric.Image(imageEl)
        imageRef.current = image
        const img = imageRef.current
        img.hasControls = false
        img.hoverCursor = 'cursor'
        img.lockMovementX = true
        img.lockMovementY = true
        img.lockRotation = true
        img.selectable = false
        canvasRef.current.add(img)
        img.sendToBack()
        resizeCanvas()
        canvasRef.current?.renderAll()
        loaded.current = true
      }
    }
    reader.readAsDataURL(image)
  }, [image, resizeCanvas])

  const loadBase64Image = useCallback(() => {
    fabric.Image.fromURL(image, (image) => {
      imageRef.current = image
      const img = imageRef.current
      img.hasControls = false
      img.hoverCursor = 'cursor'
      img.lockMovementX = true
      img.lockMovementY = true
      img.lockRotation = true
      img.selectable = false
      canvasRef.current?.add(img)
      img.sendToBack()
      resizeCanvas()
      canvasRef.current?.renderAll()
      loaded.current = true
    })
  }, [image, resizeCanvas])

  useLayoutEffect(() => {
    canvasRef.current = initFabric(canvasId)
    canvasRef.current.on('mouse:wheel', (opt) => {
      if (!canvasRef.current) {
        return
      }
      const delta = opt.e.deltaY
      let zoom = canvasRef.current?.getZoom()
      zoom *= 0.999 ** delta
      if (zoom > 20) zoom = 20
      if (zoom < 0.01) zoom = 0.01
      canvasRef.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
      opt.e.preventDefault()
      opt.e.stopPropagation()
    })

    if (isFile) {
      loadFile()
    } else {
      loadBase64Image()
    }
  }, [])

  return {
    canvasRef,
    containerRef,
    loaded
  }
}
