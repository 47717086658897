/**
 *
 * "Pre-Shut Report page for viewing and interact Pre-Shut Reprot Form and go for panel selection and
 * export report to .DocX file"
 *
 * @file   PreShutPage.js
 * @author Lateral
 * @since  2023
 */
import { Box, Paper, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import PreShutForm from './components/preShut/PreShutForm'
import PreShutPanelSelect from './components/preShut/PreShutPanelSelect'
import { companyName, screensName, shutStartDateName, siteName, titleName } from './common/formNames'
import dayjs from 'dayjs'
import { useCurrentUser, useMaintenance } from 'hooks'
import { toPng } from 'html-to-image'
import BigNumber from 'bignumber.js'
import { Storage } from 'aws-amplify'
import { saveAs } from 'file-saver'
import createReport from 'docx-templates'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import { reportTypes } from './common/reportTypes'
import { createReport as createReportModel } from './common/data'
import { DeckRevisionHistory } from 'models'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { dateFormat } from 'common/dates'
import { getKitTable } from './common/generation'

function PreShutPage() {
  /**
   * Generates Pre-shut Report page with Pre-Shut Report Form to view on desktop sized screen
   *
   * @function
   *
   * @returns {object} - Pre-Shut Report page
   */
  const theme = useTheme()
  const { database } = useContext(CurrentDeckContext)
  const { actions, getAction, wearColours } = useMaintenance()
  const { getTransactionMetaData } = useCurrentUser()

  const [selectedPreShutDeck, setSelectedPreShutDeck] = useState('')
  const { existingReport, setExistingReport } = useContext(ReportingContext)

  const [isReportDirty, setIsReportDirty] = useState(false)

  const [formData, setFormData] = useState(
    Object.entries(existingReport).length
      ? {
          id: existingReport.id,
          [companyName]: database.customers.find((c) => c.Name === existingReport.Company)?.id,
          [siteName]: database.sites.find((s) => s.Name === existingReport.Site)?.id,
          [shutStartDateName]: existingReport.DateOfVisit,
          [titleName]: existingReport.Title
        }
      : {}
  )
  const [preShutDecks, setPreShutDecks] = useState(
    Object.entries(existingReport).length ? parsePanelLayout(existingReport.PreShutPanelLayout) : []
  )

  function parsePanelLayout(panelLayout) {
    const layout =
      typeof panelLayout === 'string' || typeof panelLayout[0] === 'string' ? JSON.parse(panelLayout) : panelLayout

    return layout.map((s) => {
      const generated = generatePanelLayout(s.id)
      return {
        id: s.id,
        Name: generated.Name,
        DeckRevision: generated.DeckRevision,
        Histories: generated.Histories,
        InspectDeckRails: s.InspectDeckRails,
        InspectCrossbeams: s.InspectCrossbeams,
        PanelLayout: s.PanelLayout
      }
    })
  }

  function onSetPanelLayout(panelLayout) {
    const currentDecks = structuredClone(preShutDecks)
    let currentDeck = currentDecks.find((c) => c.id === selectedPreShutDeck)
    if (currentDeck) {
      currentDeck.PanelLayout = panelLayout
    }

    setPreShutDecks(currentDecks)
  }

  function onUpdatePreShutDeck(preShutDeck) {
    const currentDecks = structuredClone(preShutDecks)
    let currentDeck = currentDecks.find((c) => c.id === selectedPreShutDeck)
    if (currentDeck) {
      currentDeck.InspectDeckRails = preShutDeck.InspectDeckRails
      currentDeck.InspectCrossbeams = preShutDeck.InspectCrossbeams
      currentDeck.PanelLayout = preShutDeck.PanelLayout
    }

    setPreShutDecks(currentDecks)
  }

  //generate report
  async function onSubmit(form, isDirty) {
    const customer = database.customers.find((c) => c.id === form[companyName])
    const site = database.sites.find((s) => s.id === form[siteName])

    const legendSize = 0.5
    const base64Prepend = 'data:image/png;base64,'
    const title = form[titleName] ? form[titleName] : customer.Name

    let model = existingReport?.Type === reportTypes.PreShut ? structuredClone(existingReport) : {}
    model.Type = reportTypes.PreShut
    model.CustomerId = customer.id
    model.Company = customer.Name
    model.SiteId = site.id
    model.Site = site.Name
    model.Title = title
    model.DateOfVisit = dayjs(form[shutStartDateName]).toISOString()
    model.Selections = form[screensName].filter((j) => j.Include).map((j) => j.DeckId)
    model.PreShutPanelLayout = preShutDecks.map((p) => {
      return {
        InspectDeckRails: p.InspectDeckRails,
        PanelLayout: p.PanelLayout,
        id: p.id,
        InspectCrossbeams: p.InspectCrossbeams
      }
    })

    if (!model.Revision) {
      model.Revision = 0
    }

    //only updated if creating, or editing and something was changed.
    if (isDirty || isReportDirty) {
      model.Revision += 1
      const result = await createReportModel(model, getTransactionMetaData())
      setExistingReport(result)
      setIsReportDirty(false)
    }

    //gather data
    const data = {
      Company: customer.Name,
      Site: site.Name,
      Title: title,
      DateOfVisit: dayjs(form[shutStartDateName]).format(dateFormat),
      Decks: await Promise.all(
        preShutDecks.map(async (preShutDeck) => {
          const history = preShutDeck.PanelLayout
          const deckRevision = preShutDeck.DeckRevision
          const deck = database.decks.find((d) => d.id === deckRevision.DeckId)
          const location = database.locations.find((l) => l.id === deck.LocationId)
          const screen = database.screens.find((s) => s.id === deck.ScreenId)
          const formEntry = form[screensName].find((s) => s.DeckId === deck.id)

          //reveal, render, then hide canvases
          formEntry.maintenanceHtmlElement.hidden = false
          const maintenanceImage = await toPng(formEntry.maintenanceHtmlElement)
          formEntry.maintenanceHtmlElement.hidden = true
          formEntry.layoutHtmlElement.hidden = false
          const layoutImage = await toPng(formEntry.layoutHtmlElement)
          formEntry.layoutHtmlElement.hidden = true

          const itemList = {}
          history.Details.forEach((detail) => {
            const materialNumber = detail.Panel?.MaterialNumber ?? detail.SideLiner?.MaterialNumber
            const kit = deckRevision.Kits.find((k) => k.MaterialNumber === materialNumber)
            if (materialNumber && detail.HistoryAction !== actions.NoChange.id) {
              const similarDetails = history.Details.filter(
                (d) =>
                  (d.Panel?.MaterialNumber === materialNumber || d.SideLiner?.MaterialNumber === materialNumber) &&
                  d.HistoryAction === detail.HistoryAction
              )
              const key = `${materialNumber}-${detail.HistoryAction}-${similarDetails.length}`

              if (!itemList[key]) {
                const action = getAction(detail.HistoryAction)
                itemList[key] = {
                  MaterialNumber: kit.MaterialDescription ? kit.MaterialNumber : '',
                  HistoryAction: detail.HistoryAction,
                  HistoryState: action.state,
                  SchenckNumber: screen.SchenckSerial,
                  PartNumber: kit.PartNumber,
                  Description: kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber,
                  Quantity: similarDetails.length
                }
              }
            }
          })

          return {
            Name: `${location.Name} - ${screen.Name} - ${deck.DeckHeader.EquipmentId} - ${deck.DeckHeader.DeckLevel}`,
            Location: location.Name,
            MaintenanceImage: {
              data: maintenanceImage.slice(base64Prepend.length),
              extension: '.png',
              width: 16,
              height: new BigNumber(deck.DeckHeader.DeckSize.Rows).dividedBy(2).toNumber()
            },
            LayoutImage: {
              data: layoutImage.slice(base64Prepend.length),
              extension: '.png',
              width: 16,
              height: new BigNumber(deck.DeckHeader.DeckSize.Rows).dividedBy(2).toNumber()
            },
            Equipment: deck.DeckHeader.EquipmentId,
            ScreenNumber: screen.Name,
            SchenckNumber: screen.SchenckSerial,
            MaterialNumber: deck.DeckHeader.MaterialNumber,
            InspectDeckRails: preShutDeck.InspectDeckRails,
            InspectCrossbeams: preShutDeck.InspectCrossbeams,
            ItemsChanged: Object.values(itemList),
            KitTable: getKitTable(deckRevision)
          }
        })
      ),
      Legend: {
        Replace: {
          data: actions.Replace.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Rotate: {
          data: actions.Rotate.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Swap: {
          data: actions.Swap.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        RelocateFrom: {
          data: actions.Relocate.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        RelocateTo: {
          data: actions.RelocateTo.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear0: {
          data: wearColours.zero.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear20: {
          data: wearColours.twenty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear40: {
          data: wearColours.forty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear60: {
          data: wearColours.sixty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear80: {
          data: wearColours.eighty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        }
      },
      ItemsSummary: []
    }

    //generate the summary
    data.Decks.forEach((deck) => {
      deck.ItemsChanged.forEach((item) => {
        if (item.HistoryAction === actions.Replace.id || item.HistoryAction === actions.Relocate.id) {
          const existing = data.ItemsSummary.find(
            (i) => i.MaterialNumber === item.MaterialNumber && i.SchenckNumber === item.SchenckNumber
          )
          if (existing) {
            existing.Quantity = new BigNumber(existing.Quantity).plus(item.Quantity).toNumber()
          } else {
            data.ItemsSummary.push(structuredClone(item))
          }
        }
      })
    })

    //generate DocX
    const template = await Storage.get('preshut.docx', { download: true })
    const report = await createReport({ template: template.Body, data: data, cmdDelimiter: ['{#', '#}'] })

    const reportBlob = new Blob([report], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    })

    saveAs(reportBlob, `${title.replace(/[^a-z0-9]/gi, '_')}.docx`)
  }

  function getDeckRevision(id) {
    const revisions = database.deckRevisions
      .filter((d) => d.DeckId == id && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
    if (revisions.length) {
      const revision = structuredClone(revisions[0])
      return revision
    }
  }

  function generatePanelLayout(deckId) {
    const existing = preShutDecks?.find((p) => p.id === deckId)

    if (existing) {
      return existing
    } else {
      const deck = database.decks.find((d) => d.id === deckId)
      const location = database.locations.find((l) => l.id === deck.LocationId)
      const screen = database.screens.find((s) => s.id === deck.ScreenId)
      const deckRevision = getDeckRevision(deckId)
      const histories = database.deckRevisionHistories.filter((d) => d.RevisionId === deckRevision.id)
      return {
        id: deckId,
        Name: `${location.Name} - ${deck.DeckHeader.EquipmentId} - ${screen.Name} - ${deck.DeckHeader.DeckLevel}`,
        DeckRevision: deckRevision,
        Histories: histories,
        InspectDeckRails: false,
        InspectCrossbeams: false,
        PanelLayout: new DeckRevisionHistory({ Details: [] })
      }
    }
  }

  return (
    <Box sx={selectedPreShutDeck ? { height: '95%', width: '100%' } : { height: '76%', width: '50%' }}>
      {selectedPreShutDeck ? null : (
        <Typography variant="h4" component="h4" sx={{ marginTop: '1em' }}>
          Pre-Shut Report
        </Typography>
      )}
      <Paper
        elevation={6}
        sx={{
          backgroundImage: 'none',
          height: '100%',
          marginTop: '0.5em',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        {/* User fills PreShutForm, adds data for PreShutPanelSelect, then confirms via PreShut Form */}
        {selectedPreShutDeck ? (
          <PreShutPanelSelect
            setIsReportDirty={setIsReportDirty}
            selectedPreShutDeck={selectedPreShutDeck}
            setSelectedPreShutDeck={setSelectedPreShutDeck}
            preShutDecks={preShutDecks}
            setPreShutDecks={setPreShutDecks}
            onUpdatePreShutDeck={onUpdatePreShutDeck}
            onSetPanelLayout={onSetPanelLayout}
          />
        ) : (
          <PreShutForm
            data={formData}
            preShutDecks={preShutDecks}
            setSelectedPreShutDeck={setSelectedPreShutDeck}
            setFormData={setFormData}
            setPreShutDecks={setPreShutDecks}
            getDeckRevision={getDeckRevision}
            generatePanelLayout={generatePanelLayout}
            onSubmit={onSubmit}
            customers={database.customers}
            sites={database.sites}
            locations={database.locations}
            decks={database.decks}
          />
        )}
      </Paper>
    </Box>
  )
}
export default PreShutPage
