/**
 *
 * "CRUD (Create, read, update, and delete)
 * functions for Roles, Customers, Site, and Customers
 *
 * @file   data.js
 * @author Lateral
 * @since  2023
 */
import { DataStore } from 'aws-amplify'
import { ContactItem, Role, UserRole } from 'models'
import { log } from 'common'
import { Customers } from 'models'
import { Sites } from 'models'
import { Locations } from 'models'
import { addUserToGroup, adminUpdateUserAttributes, loadAndExtendUsers, removeUserFromGroup } from './adminDatastore'
import { Auth, API } from 'aws-amplify'

//CRUD for Roles.

export const addRole = async (data, createdBy) => {
  /**
   *
   * Create new role
   *
   * @function
   * @async
   *
   * @param {object} data - Role details
   * @param {string} data.Name - Role name
   * @param {string} data.Description - Role description
   * @param {string} data.Group - Group name where role applicable
   *
   * @returns {object} - New role object
   */
  const newRole = await DataStore.save(
    new Role({
      Name: data.Name,
      CustomerId: data.CustomerId,
      SiteIds: data.SiteIds,
      Description: data.Description,
      Scopes: data.Scopes,
      Group: data.Group,
      Enabled: data.Enabled,
      LastUpdatedBy: createdBy
    })
  )
  return newRole
}

export const updateRole = async (data, updatedBy) => {
  /**
   *
   * Update existing role
   *
   * @function
   * @async
   *
   * @param {object} data - Role details
   * @param {string} data.Description - Role description
   * @param {object} data.Scopes - Scopes of the role
   * @param {string} data.Group - Group name where role applicable
   *
   * @returns {object} - Updated role object
   */
  const original = await DataStore.query(Role, data.id)
  const originalGroup = original.Group
  const updatedGroup = data.Group
  const originalCustomer = original.CustomerId
  const originalSites = original.SiteIds
  const updatedCustomer = data.CustomerId
  const updatedSites = data.SiteIds

  await DataStore.save(
    Role.copyOf(original, (updated) => {
      ;(updated.Name = data.Name),
        (updated.CustomerId = data.CustomerId),
        (updated.SiteIds = data.SiteIds),
        (updated.Description = data.Description),
        (updated.Scopes = data.Scopes),
        (updated.Group = data.Group),
        (updated.Enabled = data.Enabled),
        (updated.LastUpdatedBy = updatedBy)
    })
  )
  log.info('Role updated successfully!')

  const usersWithRole = await DataStore.query(UserRole, (userRole) => userRole.RoleId.eq(original.id))
  const userCognitoIds = usersWithRole.map((u) => u.CognitoId)

  if (originalGroup !== updatedGroup) {
    for (const userWithRole of usersWithRole) {
      const usersOwnRoles = await DataStore.query(UserRole, (userRole) => userRole.CognitoId.eq(userWithRole.CognitoId))
      const roleIds = usersOwnRoles.map((u) => u.RoleId)
      const existingRoles = await DataStore.query(Role, (role) =>
        role.and((role) => [role.or((role) => roleIds.map((r) => role.id.eq(r))), role.id.ne(userWithRole.RoleId)])
      )
      const existingRoleGroups = existingRoles.map((r) => r.Group)

      if (!existingRoleGroups.includes(originalGroup)) {
        await removeUserFromGroup(userWithRole.CognitoId, originalGroup)
      }

      if (!existingRoleGroups.includes(updatedGroup)) {
        await addUserToGroup(userWithRole.CognitoId, updatedGroup)
      }
    }

    log.info('Updated Users Groups successfully!')
  }

  //customers/sites were updated, better update ALL users too!
  if (
    originalCustomer !== updatedCustomer ||
    originalSites.some((os) => !updatedSites.includes(os)) ||
    updatedSites.some((us) => !originalSites.includes(us))
  ) {
    const users = await loadAndExtendUsers()
    const filteredUsers = users.filter((u) => userCognitoIds.includes(u.Username))

    for (const user of filteredUsers) {
      const usersOwnRoles = await DataStore.query(UserRole, (userRole) => userRole.CognitoId.eq(user.Username))
      const roleIds = usersOwnRoles.map((u) => u.RoleId)
      const existingRoles = await DataStore.query(Role, (role) =>
        role.and((role) => [role.or((role) => roleIds.map((r) => role.id.eq(r))), role.id.ne(original.id)])
      )

      const existingCustomers = [...new Set(existingRoles.map((r) => r.CustomerId))]
      const existingSites = [...new Set(existingRoles.map((r) => r.SiteIds).flat())]

      const customerIds = user.Attributes.find((a) => a.Name === 'customerName')
      let parsedCustomerIds = parseIds(customerIds.Value)
      const siteIds = user.Attributes.find((a) => a.Name === 'sites')
      let parsedSiteIds = parseIds(siteIds.Value)

      if (!existingCustomers.includes(originalCustomer)) {
        parsedCustomerIds = parsedCustomerIds.filter((p) => p !== originalCustomer)
      }

      if (!existingCustomers.includes(updatedCustomer)) {
        parsedCustomerIds.push(updatedCustomer)
      }

      const sitesToRemove = originalSites.filter((o) => !existingSites.includes(o))
      parsedSiteIds = parsedSiteIds.filter((p) => !sitesToRemove.includes(p))
      const sitesToAdd = updatedSites.filter((o) => !existingSites.includes(o))
      parsedSiteIds = parsedSiteIds.concat(sitesToAdd)
      adminUpdateUserAttributes(
        user.Username,
        user.Attributes.find((a) => a.Name === 'given_name').Value,
        user.Attributes.find((a) => a.Name === 'family_name').Value,
        JSON.stringify(parsedCustomerIds.filter((c) => c)),
        JSON.stringify(parsedSiteIds.filter((s) => s))
      )
    }

    log.info('Updated Users successfully!')
  }
}

function parseIds(ids) {
  try {
    const parsed = JSON.parse(ids)
    return parsed
  } catch {
    return [ids]
  }
}

export const enableRole = async (id) => {
  const original = await DataStore.query(Role, id)
  await DataStore.save(
    Role.copyOf(original, (updated) => {
      updated.Enabled = true
    })
  )
}

export const disableRole = async (id) => {
  const original = await DataStore.query(Role, id)
  await DataStore.save(
    Role.copyOf(original, (updated) => {
      updated.Enabled = false
    })
  )
}

export const removeRole = async (id) => {
  /**
   *
   * Remove existing role
   *
   * @function
   * @async
   *
   * @param {string} id - Role id
   *
   */
  const todelete = await DataStore.query(Role, id)
  DataStore.delete(todelete)
  log.info('Role deleted successfully!')
}

//CRUD for UserRole
export const addOrUpdateUserRole = async (data) => {
  const original = await DataStore.query(UserRole, (u) =>
    u.and((u) => [u.CognitoId.eq(data.CognitoId), u.RoleId.eq(data.RoleId)])
  )

  if (original.length) {
    return await DataStore.save(
      UserRole.copyOf(original[0], (updated) => {
        ;(updated.CognitoId = data.CognitoId), (updated.CustomerId = data.CustomerId), (updated.RoleId = data.RoleId)
      })
    )
  } else {
    return await DataStore.save(
      new UserRole({
        CognitoId: data.CognitoId,
        CustomerId: data.CustomerId,
        RoleId: data.RoleId
      })
    )
  }
}

export const removeUserRole = async (cognitoId, roleId) => {
  const original = await DataStore.query(UserRole, (u) =>
    u.and((u) => [u.CognitoId.eq(cognitoId), u.RoleId.eq(roleId)])
  )
  if (original.length) {
    DataStore.delete(original[0])
  }
}

//CRUD for Customers.

export async function addCustomer(data) {
  /**
   *
   * Create new customer
   *
   * @function
   * @async
   *
   * @param {object} data - Customer details
   * @param {string} data.Name - Customer Name
   * @param {object} data.Contact - Customer contact details
   * @param {string} data.Contact.Email - Customer email
   * @param {object} data.Contact.Name - Customer Name
   * @param {string} LogoKey - Key to the customer logo
   *
   * @returns {object} - New customer object
   */
  const newCompany = await DataStore.save(
    new Customers({
      Name: data.Name,
      Contact: new ContactItem({
        Email: data.Contact.Email,
        Name: data.Contact.Name
      }),
      LogoKey: data.LogoKey
    })
  )

  return newCompany
}

export async function updateCustomer(data) {
  /**
   *
   * Update existing customer
   *
   * @param {object} data - Customer details
   * @param {string} data.id - Customer id
   * @param {string} data.Name - Customer Name
   * @param {object} data.Contact - Customer contact details
   * @param {string} data.Contact.Email - Customer email
   * @param {object} data.Contact.Name - Customer Name
   * @param {string} LogoKey - Key to the customer logo
   * @function
   * @async
   *
   * @param {object} data - Customer details
   *
   */
  const original = await DataStore.query(Customers, data.id)
  await DataStore.save(
    Customers.copyOf(original, (updated) => {
      ;(updated.Name = data.Name), (updated.Contact = data.Contact), (updated.LogoKey = data.LogoKey)
    })
  )
}

export async function deleteCustomer(id) {
  const headers = {
    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  }

  try {
    const results = await API.del('removeCustomerApi', `/api/v1/${id}`, {
      headers
    })
    console.log('results', results)
    const original = await DataStore.query(Customers, id)
    await DataStore.delete(original)
  } catch (e) {
    console.log('e', e)
    throw e
  }
}

//CRUD for Sites.

export async function addSite(data, createdBy) {
  /**
   *
   * Create new site
   *
   *
   * @function
   * @async
   *
   * @param {object} data - Site details
   * @param {string} data.CustomerId - Customer id
   * @param {string} data.Name - Site Name
   * @param {object} data.Contacts - Customer contact details
   *
   * @returns {object} - New customer object
   */
  const newSite = await DataStore.save(
    new Sites({
      CustomerId: data.CustomerId,
      Name: data.Name,
      Contacts: data.Contacts,
      LastUpdatedBy: createdBy
    })
  )

  return newSite
}

export async function updateSite(data, updatedBy) {
  /**
   *
   * Update existing site
   *
   *
   * @function
   * @async
   *
   * @param {object} data - Site details
   * @param {string} data.id - Site id
   * @param {string} data.Name - Site Name
   * @param {object} data.Contacts - Customer contact details
   *
   * @returns {object} - New customer object
   */
  const original = await DataStore.query(Sites, data.id)
  await DataStore.save(
    Sites.copyOf(original, (updated) => {
      ;(updated.Name = data.Name), (updated.Contacts = data.Contacts), (updated.LastUpdatedBy = updatedBy)
    })
  )
}

export async function deleteSite(id) {
  /**
   *
   * Remove existing site
   *
   * @function
   * @async
   *
   * @param {string} id - Site id
   *
   */
  const original = await DataStore.query(Sites, id)
  await DataStore.delete(original)
}

//CRUD for Locations.

export async function addLocation(data) {
  /**
   *
   * Create new location
   *
   *
   * @function
   * @async
   *
   * @param {object} data - Location details
   * @param {string} data.SiteId - Site id
   * @param {string} data.Name - Location Name
   *
   * @returns {object} - New customer object
   */
  const newSite = await DataStore.save(
    new Locations({
      SiteId: data.SiteId,
      Name: data.Name
    })
  )

  return newSite
}

export async function updateLocation(data) {
  /**
   *
   * Update existing location
   *
   *
   * @function
   * @async
   *
   * @param {object} data - Location details
   * @param {string} data.id - Location id
   * @param {string} data.Name - Location Name
   *
   * @returns {object} - New customer object
   */
  const original = await DataStore.query(Locations, data.id)
  await DataStore.save(
    Locations.copyOf(original, (updated) => {
      updated.Name = data.Name
    })
  )
}

export async function deleteLocation(id) {
  /**
   *
   * Delete existing location
   *
   *
   * @function
   * @async
   *
   * @param {object} data - Location details
   * @param {string} data.id - Location id
   *
   * @returns {object} - New customer object
   */
  const original = await DataStore.query(Locations, id)
  await DataStore.delete(original)
}
