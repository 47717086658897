import React, { useEffect, useState } from 'react'
import { DeckCanvas } from 'components'
import { DeckMode } from 'common/deckMode'
import MaintenanceSummaryForm from '../components/SummaryForm'
import { addMaintenanceAction, addWearAction, setHistoryWithForm } from 'pages/common/maintenance/logic'
import { Typography, Box, Stack } from '@mui/material'
import BigNumber from 'bignumber.js'
import { useMaintenance } from 'hooks'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { MobileDeckCanvasAndMaintenanceForm } from 'pages/deck/maintenance/mobile/MobileDeckCanvasAndMaintenanceForm'
import MaintenanceLegend from 'components/deckCanvas/components/MaintenanceLegend/MaintenanceLegend'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'

export const DeckCanvasAndMaintenanceForm = () => {
  const { actions, getAction } = useMaintenance()
  const [awaitingActionId, setAwaitingActionId] = useState(-1)
  const [mouseCanvasPosition, setMouseCanvasPosition] = useState(
    JSON.parse(localStorage.getItem('mouseCanvasPosition') ?? '{"x":0,"y":0}')
  )
  const [materialSelected, setMaterialSelected] = useState('')
  const {
    history,
    setSelectedPanel,
    selectedPanel,
    deckRevision,
    deck,
    maintenanceData,
    setMaintenanceData,
    inMaintenanceMode,
    screen,
    maintenanceFrequency
  } = useCurrentDeckContext()
  const [viewType, setViewType] = useState('3D')

  useEffect(() => {
    localStorage.setItem('mouseCanvasPosition', JSON.stringify(mouseCanvasPosition))
  }, [mouseCanvasPosition])

  function onUndo() {
    const data = history.undoHistory()
    if (data) {
      setMaintenanceData(data)
    }
  }

  function onRedo() {
    const data = history.redoHistory()
    if (data) {
      setMaintenanceData(data)
    }
  }

  function onBomClick(kit) {
    setSelectedPanel([])
    setMaterialSelected(kit.MaterialNumber)
  }

  function onPanelClick(data, e) {
    if (data) {
      if (new BigNumber(awaitingActionId).isGreaterThanOrEqualTo(0)) {
        const result = addMaintenanceAction(
          getAction(awaitingActionId),
          awaitingActionId,
          [data],
          maintenanceData,
          actions
        )

        setMaintenanceData(result.clone)
        history.addHistory(result.clone)
        setAwaitingActionId(result.actionId)
        setSelectedPanel([])
      } else {
        const position = { x: e.layerX, y: e.layerY }
        setMouseCanvasPosition(position)
      }
    }
    setMaterialSelected('')
  }

  function onSelectChange(data) {
    if (new BigNumber(awaitingActionId).isLessThan(-1)) {
      setSelectedPanel([])
      setMaterialSelected('')
      setAwaitingActionId(-1)
    } else {
      setSelectedPanel(data)
      setMaterialSelected('')
    }
  }

  function cancelAwaitingAction() {
    if (new BigNumber(awaitingActionId).isGreaterThanOrEqualTo(0)) {
      let clone = structuredClone(maintenanceData)
      clone.deckRevisionHistory.Details.pop()
      setMaintenanceData(clone)
      setAwaitingActionId(-1)
    }
  }

  //various submissions that update on the fly, such as WearApp, the SummaryForm, adding an Action, etc.
  function onFormChange(form) {
    const clone = setHistoryWithForm(form, maintenanceData)
    history.addHistory(clone)
    setMaintenanceData(clone)
  }

  function onMaintenanceTypeClick(action, extraData) {
    const result = addMaintenanceAction(action, awaitingActionId, selectedPanel, maintenanceData, actions, extraData)
    setMaintenanceData(result.clone)
    if (result.actionId < 0) {
      history.addHistory(result.clone)
    }

    setAwaitingActionId(result.actionId)
    setSelectedPanel([])
  }

  function onWearSubmit(form) {
    const clone = addWearAction(form, maintenanceData, selectedPanel, actions.NoChange.id)
    setMaintenanceData(clone)
    history.addHistory(clone)
    setSelectedPanel([])
  }

  const switchViewType = () => {
    setSelectedPanel([])
    setViewType((currentViewType) => (currentViewType === '3D' ? '2D' : '3D'))
  }
  const hasFoundMaintenanceFreq = typeof maintenanceFrequency === 'undefined'

  return (
    <Box
      display="grid"
      height="100%"
      width="100%"
      columnGap="17px"
      minHeight="0"
      gridTemplateColumns="minmax(0, 50%) 50%"
      gridTemplateRows="180px 1fr"
      justifyContent="stretch"
      alignItems="stretch">
      <Box gridRow="span 2" minHeight="0" minWidth="0" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography className="title" variant="h5" component="h5">
          {`${screen.Name} - ${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}
        </Typography>
        {viewType === '3D' ? (
          <DeckCanvas
            data={deckRevision}
            maintenance={maintenanceData.deckRevisionHistory}
            mouseCanvasPosition={mouseCanvasPosition}
            onPanelClick={onPanelClick}
            onMaintenanceTypeClick={onMaintenanceTypeClick}
            selected={selectedPanel}
            materialSelected={materialSelected}
            onSelectChange={onSelectChange}
            onWearSubmit={onWearSubmit}
            awaitingActionId={awaitingActionId}
            cancelAwaitingAction={cancelAwaitingAction}
            mode={inMaintenanceMode ? DeckMode.maintenance : DeckMode.default}
            onUndo={onUndo}
            onRedo={onRedo}
            isHistoryEmpty={history.isEmpty}
            isHistoryAtEnd={history.isAtEnd}
            onSwitchView={switchViewType}
          />
        ) : (
          <>
            <MobileDeckCanvasAndMaintenanceForm onSwitchView={switchViewType} />
            {inMaintenanceMode && <MaintenanceLegend awaitingActionId={awaitingActionId} />}
          </>
        )}
      </Box>

      <Stack
        gridRow="span 2"
        minHeight="0"
        minWidth="0"
        overflow="hidden"
        justifyContent={!hasFoundMaintenanceFreq ? 'center' : undefined}
        alignItems={!hasFoundMaintenanceFreq ? 'center' : undefined}
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '0', gap: '20px' }}>
        {hasFoundMaintenanceFreq ? (
          <ProgressIndicator />
        ) : (
          <MaintenanceSummaryForm
            data={maintenanceData}
            deckRevision={deckRevision}
            selected={selectedPanel}
            materialSelected={materialSelected}
            onBomClick={onBomClick}
            onChange={onFormChange}
            isMobile={false}
            inMaintenanceMode={inMaintenanceMode}
          />
        )}
      </Stack>
    </Box>
  )
}
