/**
 *
 * "Component for rendering a 3D canvas to
 * select replaced Panels for a PreShut Report with
 * Recent maintenance and B.O.M. table"
 *
 * @file   PreShutPanelSelect.js
 * @author Lateral
 * @since  2023
 */
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { DeckCanvas } from 'components'
import BomTable from 'components/tables/bom/BomTable'
import { useMaintenance } from 'hooks/maintenance/useMaintenance'
import { addMaintenanceAction, findExisting, findExistingAndRemove } from 'pages/common/maintenance/logic'
import React, { useContext, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RecentMaintenanceTable from 'components/tables/maintenance/RecentMaintenanceTable'
import { ReportingContext } from 'components/reportingContext/ReportingContext'

function PreShutPanelSelect({
  selectedPreShutDeck,
  setSelectedPreShutDeck,
  preShutDecks,
  setPreShutDecks,
  onUpdatePreShutDeck,
  onSetPanelLayout,
  setIsReportDirty
}) {
  /**
   * Table for viewing all Reporting history.
   *
   * @function
   *
   * @param {} setSelectedPreShutDeck -To update selectedPreShutDecks hook
   * @param {object[]} preShutDecks - Selected deck layouts
   * @param {} setPreShutDecks - To update preShutDecks hook
   * @param {} onUpdatePreShutDeck - Action on update PreShutDeck hook
   * @param {} onSetPanelLayout - Action on det PanelLayout hook
   * @param {} setIsReportDirty - To update isReportDirty hook
   *
   * @returns {object} - Components for Pre-Shut Report panel selection page
   */
  const [selected, setSelected] = useState([])
  const [materialSelected, setMaterialSelected] = useState('')
  const { setShowTabs, setExistingReport } = useContext(ReportingContext)

  const { actions } = useMaintenance()
  const theme = useTheme()
  const index = preShutDecks.findIndex((p) => p.id === selectedPreShutDeck)
  const preShutDeck = preShutDecks.find((p) => p.id === selectedPreShutDeck)
  const deckRevisionHistory = preShutDeck.PanelLayout

  function onBomClick(kit) {
    setSelected([])
    setMaterialSelected(kit.MaterialNumber)
  }

  //Panel logic is much simpler here. Only Replace can be selected, no radial menu required.
  function onSelectChange(data) {
    if (data.length > 1) {
      let clone = structuredClone(deckRevisionHistory)

      for (const item of data) {
        const panel = item.Position ? item : null
        const sideliner = item.StartPosition !== null && item.StartPosition !== undefined ? item : null
        const existing = findExisting(clone, panel, sideliner)

        if (!Object.entries(existing).length) {
          const result = addMaintenanceAction(actions.Replace, -1, [item], { deckRevisionHistory: clone }, actions)
          clone = result.clone.deckRevisionHistory
        }
      }

      const toRemove = selected.filter((s) => !data.find((d) => JSON.stringify(d) === JSON.stringify(s)))

      for (const item of toRemove) {
        const panel = item.Position ? item : null
        const sideliner = item.StartPosition !== null && item.StartPosition !== undefined ? item : null
        findExistingAndRemove(clone, panel, sideliner)
      }

      onSetPanelLayout(clone)
      setSelected(data)
    } else {
      setSelected([])
    }
    setMaterialSelected('')
  }

  // Action on clicking on a panel
  function onPanelClick(data) {
    if (data) {
      setIsReportDirty(true)

      const panel = data.Position ? data : null
      const sideliner = data.StartPosition !== null && data.StartPosition !== undefined ? data : null
      const clone = structuredClone(deckRevisionHistory)

      const existing = findExistingAndRemove(clone, panel, sideliner)

      if (Object.entries(existing).length) {
        onSetPanelLayout(clone)
      } else {
        const result = addMaintenanceAction(
          actions.Replace,
          -1,
          [data],
          { deckRevisionHistory: deckRevisionHistory },
          actions
        )
        onSetPanelLayout(result.clone.deckRevisionHistory)
      }
      setMaterialSelected('')
      setSelected([])
    }
  }

  // // Action on clicking 'Inspect Deck Rails'
  // function onSwitchDeckRails() {
  //   preShutDeck.InspectDeckRails = !preShutDeck.InspectDeckRails
  //   onUpdatePreShutDeck(preShutDeck)
  // }

  // // Action on clicking 'Inspect Crossbeams'
  // function onSwitchCrossbeams() {
  //   preShutDeck.InspectCrossbeams = !preShutDeck.InspectCrossbeams
  //   onUpdatePreShutDeck(preShutDeck)
  // }

  // Action on clicking 'Previous Deck' button
  function onPreviousDeck() {
    if (index === 0) {
      setSelectedPreShutDeck('')
      setShowTabs(true)
    } else {
      const nextIndex = new BigNumber(index).minus(1).toNumber()
      const nextDeck = preShutDecks[nextIndex]
      setSelectedPreShutDeck(nextDeck.id)
    }
  }

  // Action on clicking 'Next Deck' button
  function onNextDeck() {
    if (new BigNumber(preShutDecks.length).minus(1).isEqualTo(index)) {
      setSelectedPreShutDeck('')
      setShowTabs(true)
    } else {
      const nextIndex = new BigNumber(index).plus(1).toNumber()
      const nextDeck = preShutDecks[nextIndex]
      setSelectedPreShutDeck(nextDeck.id)
    }
  }

  // Action on clicking 'Cancel Report' button
  function onCancel() {
    setSelectedPreShutDeck('')
    setExistingReport({})
    setPreShutDecks([])
    setShowTabs(true)
  }

  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Grid item md={6} xs={12} sx={{ marginBottom: '1em', height: '5%' }}>
        <Typography variant="h2">Pre-Shut Report</Typography>
      </Grid>
      <Grid
        container
        item
        md={6}
        xs={12}
        sx={{ marginBottom: '1em', justifyContent: 'end', alignItems: 'center', height: '5%' }}>
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{ color: `${theme.palette.secondary.main}`, marginRight: '2em' }}>
          Cancel Report
        </Button>
      </Grid>
      <Grid item md={6} xs={12} sx={{ height: '100%' }}>
        <Typography className="title" variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
          {preShutDeck.Name}
        </Typography>
        <Box sx={{ height: '100%' }}>
          <DeckCanvas
            data={preShutDeck.DeckRevision}
            maintenance={deckRevisionHistory}
            mouseCanvasPosition={{ x: 0, y: 0 }}
            onPanelClick={onPanelClick}
            selected={[]}
            materialSelected={materialSelected}
            onSelectChange={onSelectChange}
            awaitingActionId={-1}
            mode={DeckMode.maintenance}
            showLegend={false}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12} sx={{ height: '100%' }}>
        <Box sx={{ height: '20%', marginBottom: '2em' }}>
          <RecentMaintenanceTable
            deckRevision={preShutDeck.DeckRevision}
            deckRevisionHistories={preShutDeck.Histories}
            isMobile={false}
          />
        </Box>
        <Box sx={{ height: '65%' }}>
          <BomTable
            data={preShutDeck.DeckRevision}
            selected={[]}
            materialSelected={materialSelected}
            onClick={onBomClick}
          />
        </Box>
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
          {/* <Stack direction="row" spacing={2} alignItems="end" sx={{ height: '100%', width: '100%' }}>
            <FormControlLabel
              label="Inspect Deck Rails"
              control={
                <Checkbox
                  checked={preShutDeck.InspectDeckRails}
                  size="small"
                  onChange={onSwitchDeckRails}
                  sx={{ color: `${theme.palette.supporting.highlight}` }}
                />
              }
              sx={{ margin: '0' }}
            />
            <FormControlLabel
              label="Inspect Crossbeams"
              control={
                <Checkbox
                  checked={preShutDeck.InspectCrossbeams}
                  size="small"
                  onChange={onSwitchCrossbeams}
                  sx={{ color: `${theme.palette.supporting.highlight}` }}
                />
              }
              sx={{ margin: '0' }}
            />
          </Stack> */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="end"
            justifyContent="end"
            sx={{ height: '100%', width: '100%' }}>
            <Button
              onClick={onPreviousDeck}
              startIcon={<ArrowBackIcon />}
              sx={{ color: `${theme.palette.secondary.main}` }}>
              {index === 0 ? 'Back' : 'Previous Deck'}
            </Button>
            <Button
              onClick={onNextDeck}
              endIcon={<ArrowForwardIcon />}
              sx={{ color: `${theme.palette.secondary.main}` }}>
              {index === preShutDecks.length - 1 ? 'Done' : 'Next Deck'}
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default PreShutPanelSelect
