/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fz7ik8mvrd.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "removeCustomerApi",
            "endpoint": "https://qkh27bcill.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://uui4mtalbnebpdttk7y5ye3xaa.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-2:63f09bdb-2502-4b21-ac80-dc88fad30abc",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_UBSCmKNoN",
    "aws_user_pools_web_client_id": "679fvgutl424d13lahktpj0alm",
    "oauth": {
        "domain": "authdeckmapp-dev.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "phone",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.deckmapp.rockprocessing.sandvik/dashboard,http://localhost:3000/dashboard,https://prodhvo.d28bms27ufvlmy.amplifyapp.com/dashboard,https://main-dev.d28bms27ufvlmy.amplifyapp.com/dashboard",
        "redirectSignOut": "https://www.deckmapp.rockprocessing.sandvik/,http://localhost:3000/,https://prodhvo.d28bms27ufvlmy.amplifyapp.com/,https://main-dev.d28bms27ufvlmy.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "deckmapp1308980bef874656a26ba1cfb1b34cd2102831-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
