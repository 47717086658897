import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Role } from 'models'

/*
 * Observable for the Roles table.
 */
export function observeRoles() {
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      const roles = await DataStore.query(Role, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setRoles(roles)
    }

    const subscription = DataStore.observe(Role).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return roles
}

export default observeRoles
