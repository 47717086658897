/**
 *
 * "Logged in user details page"
 *
 * @file   AccountPage.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Box from '@mui/material/Box'
import { useCurrentUser, observeRoles, observeCustomers } from 'hooks'
import { Typography, Paper, Grid } from '@mui/material'

const AccountPage = () => {
  /**
   * Generates a page showing details of logged in user
   *
   * @const
   *
   * @returns {object} - React app page
   */
  const { currentUser } = useCurrentUser()
  const roles = observeRoles()
  const customers = observeCustomers()
  const companyNames = customers.filter((c) => currentUser.customerIds.includes(c.id)).map((c) => c.Name)

  if (!roles || roles.length <= 0) {
    return 'Loading...'
  }

  return (
    <Box>
      <Typography variant="h4">Account Overview</Typography>

      <Paper
        sx={{
          p: 5,
          maxWidth: 500,
          flexGrow: 1
        }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={3}>
            <Typography variant="h5">Email</Typography>
          </Grid>
          <Grid item md={9}>
            {' '}
            <Typography variant="body2"> {currentUser?.attributes?.email ?? '--'}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="h5">Companies</Typography>
          </Grid>
          <Grid item md={9}>
            {' '}
            <Typography variant="body2"> {companyNames.join(', ') ?? '--'}</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="h5">Roles</Typography>
          </Grid>
          <Grid item md={9}>
            {' '}
            <Typography variant="body2">
              {' '}
              {currentUser
                ?.getRoles()
                ?.map((x) => x.Name)
                .join(', ') ?? '--'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
export default AccountPage
