/**
 *
 * "Generates the panels as group of 2D boxes objects calculated from deck revisions data
 * for mobile device version"
 *
 * @file   mobileDeckCanvas\DeckPanels.js
 * @author Lateral
 * @since  2023
 */

import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { getWearColour, pickHeatColour } from 'common/heatColours'
import { toAlphabet } from 'common/numberToAlphabet'
import { useMaintenance } from 'hooks'
import React from 'react'

/*
 *
 */
export function DeckPanels({
  data,
  maintenance,
  materialSelected,
  onPanelClick,
  selected,
  scale,
  mode,
  presentation,
  heatMapData
}) {
  /**
   * Generates panels generated by incoming data in 2D space.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {number} materialSelected - Material number
   * @param {Function} onPanelClick - Action on clicking panel
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} scale - Zoom scale to view
   * @param {string} mode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @param {boolean} presentation - Mintenance mode presentation True to view higher contrast  text
   *
   *
   * @returns {object} - React box element of 2D boxes as panels
   */

  const kits = data.Kits
  const size = data.Size
  const theme = useTheme()
  const { actions, getAction } = useMaintenance()
  const interactionColour = theme.palette.secondary.main
  const emptyColour = theme.palette.primary.dark

  let panels = [...Array(size.Rows)].map(() => Array(size.Columns))
  data.Panels.map((p) => {
    return (panels[p.Position.Row][p.Position.Column] = p)
  })

  return (
    <Box sx={{ height: 'fit-content', width: 'fit-content' }}>
      <Stack key="columns" direction="row">
        {[...Array(size.Columns)].map((x, i) => {
          return (
            <Typography
              key={i}
              fontSize={12}
              sx={[{ width: '100%', textAlign: 'center' }, presentation ? { color: 'black' } : null]}>
              {toAlphabet(new BigNumber(i).plus(1))}
            </Typography>
          )
        })}
      </Stack>

      {panels.map((row, i) => {
        return (
          <Stack key={`row-${i}`} direction="row" sx={{ height: '1em' }}>
            {row.map((col, j) => {
              const kit = kits.find((k) => k.MaterialNumber === col?.MaterialNumber)
              const panelSize = kit?.Size
              const isMaterialSelected = materialSelected === col?.MaterialNumber
              const isSelected =
                selected.filter(
                  (s) =>
                    s.MaterialNumber == col?.MaterialNumber &&
                    s.Position?.Column === col?.Position?.Column &&
                    s.Position?.Row === col?.Position?.Row
                ).length > 0

              let colour =
                isSelected || isMaterialSelected
                  ? interactionColour
                  : mode.includes(DeckMode.maintenance)
                  ? emptyColour
                  : kit?.Colour ?? emptyColour

              const height = `${new BigNumber(panelSize?.Height ?? 1).multipliedBy(scale).toNumber()}em`
              const width = `${new BigNumber(panelSize?.Width ?? 1).multipliedBy(scale).toNumber()}em`

              let maintenanceAction = null

              //if in Maintenance mode, change the colours
              if (mode.includes(DeckMode.maintenance) && maintenance) {
                const column = new BigNumber(col.Position.Column)
                const row = new BigNumber(col.Position.Row)
                const detail = maintenance.Details?.find(
                  (d) => column.isEqualTo(d.Panel?.Position.Column) && row.isEqualTo(d.Panel?.Position.Row)
                )

                if (detail) {
                  maintenanceAction = getAction(detail.HistoryAction)
                  if (
                    (mode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
                    (mode === DeckMode.preMaintenance &&
                      (detail.WorkingDepth ||
                        detail.WorkingDepth === 0 ||
                        detail.ApertureWidth ||
                        detail.ApertureWidth === 0))
                  ) {
                    colour = getWearColour(detail, theme)
                  } else if (mode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
                    colour = 'white'
                  }
                }
              } else if (mode === DeckMode.heatMap) {
                const panelHeatData = heatMapData.Panels.find(
                  (p) => p.Column === col?.Position?.Column && p.Row === col?.Position?.Row
                )

                const wearCount = new BigNumber(panelHeatData?.WearCount ?? 0)
                const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
                const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
                colour = pickHeatColour(heatCount, theme)
              }

              return (
                <Box
                  key={`panel-${i}-${j}`}
                  sx={{
                    '&:hover': {
                      backgroundColor: interactionColour
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onPanelClick(col, e)
                  }}>
                  {maintenanceAction &&
                  maintenanceAction.id !== actions.NoChange.id &&
                  (mode === DeckMode.maintenance || mode === DeckMode.postMaintenance) ? (
                    <img
                      src={`${maintenanceAction.svg}`}
                      style={{
                        border: '1px solid black',
                        height: `${height}`,
                        width: `${width}`,
                        verticalAlign: 'top'
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: `${colour}`,
                        border: '1px solid black',
                        width: { width },
                        height: { height },
                        '&:hover': {
                          backgroundColor: interactionColour
                        }
                      }}
                    />
                  )}
                </Box>
              )
            })}
          </Stack>
        )
      })}
    </Box>
  )
}
