import { useState, useEffect } from 'react'
import { DataStore } from 'aws-amplify'
import { UserRole } from 'models'

/*
 * Observable for the UserRoles table.
 */
export function observeUserRoles() {
  const [userRoles, setUserRoles] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      const roles = await DataStore.query(UserRole)
      setUserRoles(roles)
    }

    const subscription = DataStore.observe(UserRole).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return userRoles
}

export default observeUserRoles
