/**
 *
 * "Provides the sideliners as group of 3D cube objects calculated from deck  revisions data from database"
 *
 * @file   SideLiner.js
 * @author Lateral
 * @since  2023
 */
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { getWearColour, pickHeatColour } from 'common/heatColours'
import { useMaintenance } from 'hooks'
import React from 'react'
import { Cube } from '../Cube/Cube'

export function SideLiner({
  data,
  maintenance,
  heatMapData,
  kits,
  materialSelected,
  onPanelClick,
  position,
  selected,
  size,
  theme,
  emptyTexture,
  deckMode
}) {
  /**
   * Provides group of 3D cube objects generated for sideliners calculated from deck rivion data.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {object} heatMapData - Heat map data of all anels and sideliner
   * @param {object[]} kits - Item details including material details of panels and sideliners
   * @param {number} materialSelected - Selected sideliner material number
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {number[]} position - 3-dimensional position of sideliners
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} size - size of the  sideliners
   * @param {object} theme - Panel theme object
   * @param {object} emptyTexture - Image object with default texture for panels
   * @param {string} deckMode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {object} - React group of 3D cubes
   */
  const sideLinerItems = data.sort((a, b) => a.StartPosition - b.StartPosition)
  const emptyColour = theme.palette.primary.dark
  const { actions, getAction } = useMaintenance()

  let totalYCoordinate = new BigNumber(0)
  let previousHeight = 2

  return (
    <group position={position}>
      {sideLinerItems.map((item) => {
        const offset = new BigNumber(previousHeight).minus(item.Height).dividedBy(2)
        const kit = kits.find((k) => k.MaterialNumber === item.MaterialNumber)
        let colour = deckMode.includes(DeckMode.maintenance) ? emptyColour : kit?.Colour ?? emptyColour
        const isMaterialSelected = materialSelected == item.MaterialNumber
        const isSelected =
          selected.filter(
            (s) =>
              s.MaterialNumber == item.MaterialNumber && s.StartPosition == item.StartPosition && s.Side == item.Side
          ).length > 0
        const yCoordinate = totalYCoordinate.plus(offset)
        totalYCoordinate = totalYCoordinate.minus(item.Height).plus(offset)
        previousHeight = item.Height

        let texture = emptyTexture
        //if in Maintenance mode, change colours
        if (deckMode.includes(DeckMode.maintenance) && maintenance) {
          const startPosition = new BigNumber(item.StartPosition)
          const detail = maintenance.Details?.find(
            (d) => startPosition.isEqualTo(d.SideLiner?.StartPosition) && item.Side === d.SideLiner?.Side
          )

          if (detail) {
            const maintenanceAction = getAction(detail.HistoryAction)

            if (deckMode === DeckMode.maintenance || deckMode === DeckMode.postMaintenance) {
              texture = maintenanceAction.texture
            }

            if (
              (deckMode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
              (deckMode === DeckMode.preMaintenance &&
                (detail.WorkingDepth || detail.WorkingDepth === 0) &&
                (detail.ApertureWidth || detail.ApertureWidth === 0))
            ) {
              colour = getWearColour(detail, theme)
            } else if (deckMode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
              colour = 'white'
            }
          }
          //if in HeatMap mode, also change colours
        } else if (deckMode === DeckMode.heatMap) {
          const sideLinerHeatData = heatMapData.SideLiners.find(
            (s) => s.Side === item.Side && s.StartPosition === item.StartPosition
          )

          const wearCount = new BigNumber(sideLinerHeatData.WearCount)
          const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
          const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
          colour = pickHeatColour(heatCount, theme)
        }

        return (
          <Cube
            key={`${item.Side}-${item.StartPosition}`}
            colour={colour}
            selectedColour={theme.palette.secondary.main}
            data={item}
            size={[size, new BigNumber(item.Height).times(size).toNumber(), new BigNumber(size).times(1.5).toNumber()]}
            position={[0, yCoordinate.toNumber(), 0]}
            texture={texture}
            selected={isSelected}
            materialSelected={isMaterialSelected}
            onPanelClick={onPanelClick}
          />
        )
      })}
    </group>
  )
}
