import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import React, { createContext, useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { Auth } from 'aws-amplify'
import { MaintenanceNotifier } from '../MaintenanceNotifier'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { addMaintenanceAction } from 'pages/common/maintenance/logic'

const WearAppAnalysisContext = createContext({
  // eslint-disable-next-line no-unused-vars
  startWearAnalysis: ({ photoKey, requestUUID, templateCoords, replaceProps, efficiencyProps }) => {},
  isConnected: false,
  addNotification: () => {},
  currentAnalysisResultsRef: {
    current: {
      results: {},
      depths: {},
      efficiency: {}
    }
  },
  // eslint-disable-next-line no-unused-vars
  onViewResultsPressed: (key) => {}
})

export const useWearAppAnalysisContext = () => useContext(WearAppAnalysisContext)

export const WearAppAnalysisContextProvider = ({ children }) => {
  const {
    customerId,
    deckId,
    siteId,
    inMaintenanceMode,
    setWearAnalysisResults,
    maintenanceData,
    setSelectedPanel,
    setMaintenanceData,
    history,
    actions,
    isDesktop
  } = useCurrentDeckContext()

  const [isConnected, setIsConnected] = useState(false)
  const analysisResulstRef = useRef(JSON.parse(localStorage.getItem('wearAnalysisResults') ?? '{}'))
  const photoKeysRef = useRef({})
  const errorsRef = useRef({})
  const currentAnalysisResultsRef = useRef()
  const selectedPanelRef = useRef({})
  const [notification, setNotification] = useState()
  const navigate = useNavigate()

  const onViewResultsPressed = (key) => {
    currentAnalysisResultsRef.current = analysisResulstRef.current[key]
    navigate(`/${!isDesktop ? 'mobile' : 'equipment'}/maintenance/wearapp/results`)
    onNotifierClose()
  }

  const getSocketUrl = useCallback(async () => {
    const credentials = await Auth.currentSession()
    const token = credentials.getAccessToken().getJwtToken()

    return `${process.env.REACT_APP_WEARAPP_BACKEND_URL}?authorization=${token}&app=deckmapp&siteId=${siteId}`
  }, [siteId])

  const { readyState, sendJsonMessage, getWebSocket } = useWebSocket(
    getSocketUrl,
    {
      onOpen: () => {
        setIsConnected(true)
      },
      onError: () => {
        setIsConnected(false)
      },
      onMessage: (event) => {
        const data = JSON.parse(event.data ?? '{}')
        switch (data.type) {
          case 'start-wear-analysis': {
            if (data.error === true) {
              errorsRef.current = {
                ...errorsRef.current,
                [data.uuid]: data.errorMessage
              }
              addNotification({
                children: data.errorMessage,
                type: 'error',
                autoHideDuration: 3000,
                key: 'wear_analysis_error'
              })
            } else {
              const {
                heatMapImageUrl,
                histogramImageUrl,
                appertureDetectionImageUrl,
                results,
                panel,
                depths,
                replacePanelDecisionResults,
                efficiencyResults,
                imageKeys
              } = data.data
              const wearResults = {
                heatMapImageUrl,
                appertureDetectionImageUrl,
                histogramImageUrl,
                results,
                panel,
                depths,
                replacePanel: replacePanelDecisionResults,
                efficiency: efficiencyResults,
                imageKeys,
                uuid: data.uuid
              }
              analysisResulstRef.current = {
                ...analysisResulstRef.current,
                [data.uuid]: wearResults
              }

              addNotification({
                children: (
                  <span>
                    Wear Analysis result is ready to be viewed.
                    <Button onClick={() => onViewResultsPressed(data.uuid)}>Click here to view it</Button>
                  </span>
                ),
                type: 'success',
                autoHideDuration: 6000,
                key: 'wear_analysis_success'
              })

              const result = addMaintenanceAction(
                actions.ViewWearAppResult,
                actions.ViewWearAppResult.id,
                selectedPanelRef.current[data.uuid],
                maintenanceData,
                actions,
                {
                  uuid: data.uuid,
                  panel: selectedPanelRef.current[data.uuid]
                }
              )
              setMaintenanceData(result.clone)
              if (result.actionId < 0) {
                history.addHistory(result.clone)
              }
              setWearAnalysisResults((current) => ({
                ...current,
                [data.uuid]: wearResults
              }))

              setSelectedPanel([])
            }
            break
          }
        }
      },
      shouldReconnect: () => {
        return inMaintenanceMode
      }
    },
    inMaintenanceMode
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: 'keep-warm'
      })
    }
  }, [readyState, sendJsonMessage])

  const startWearAnalysis = useCallback(
    async ({ photoKey, requestUUID, templateCoords, replaceProps, efficiencyProps, selectedPanel, requestFolder }) => {
      const credentials = await Auth.currentSession()
      selectedPanelRef.current = {
        ...selectedPanelRef.current,
        [requestUUID]: selectedPanel
      }
      photoKeysRef.current = {
        ...photoKey.current,
        [requestUUID]: photoKey
      }
      sendJsonMessage({
        type: 'start-wear-analysis',
        data: {
          panel: selectedPanel.MaterialNumber,
          templateCoords,
          templateImage: photoKey,
          useCustomBucket: true,
          app: 'deckmapp',
          uuid: requestUUID,
          replaceProps,
          efficiencyProps,
          s3Props: {
            customerId,
            deckId
          },
          auth: {
            authorization: credentials.getIdToken().getJwtToken(),
            siteId
          },
          requestFolder: `${requestFolder}/results`
        }
      })
    },
    [sendJsonMessage, customerId, deckId]
  )

  const addNotification = useCallback((notification) => {
    setNotification(notification)
  }, [])

  const onNotifierClose = () => {
    setNotification(undefined)
  }

  useEffect(() => {
    if (!inMaintenanceMode) {
      getWebSocket()?.close()
    }
  }, [inMaintenanceMode])

  useEffect(() => {
    return () => {
      getWebSocket()?.close()
    }
  }, [])
  const value = useMemo(() => {
    return {
      isConnected,
      startWearAnalysis,
      addNotification,
      currentAnalysisResultsRef,
      onViewResultsPressed,
      selectedPanelRefs: selectedPanelRef.current
    }
  }, [isConnected, startWearAnalysis, addNotification, onViewResultsPressed])

  return (
    <WearAppAnalysisContext.Provider value={value}>
      {children}

      {!!notification && (
        <MaintenanceNotifier
          type={notification.type}
          autoHideDuration={notification.autoHideDuration}
          onClose={onNotifierClose}>
          {notification.children}
        </MaintenanceNotifier>
      )}
    </WearAppAnalysisContext.Provider>
  )
}
