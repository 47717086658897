/**
 *
 * "Edit deck page to view and interact all components for deck editing"
 *
 *
 * @file   EditDeckPage.js
 * @author Lateral
 * @since  2023
 */

import { DeckCanvas } from 'components'
import { Grid, Typography } from '@mui/material'
import BomTable from 'components/tables/bom/BomTable'
import React, { useEffect, useState } from 'react'
import { usePrompt } from 'hooks'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import CreateRevision from './components/createRevision/CreateRevision'
import KitItemForm from './components/kitItemForm/KitItemForm'
import CreateDeckButtons from './components/createDeck/CreateDeckButtons'
import BigNumber from 'bignumber.js'
import { PanelPositionItems, PanelsItems, SideLinersItems } from 'models'
import { DeckMode } from 'common/deckMode'
import { loseDataWarning } from 'common/messaging'
import { DeckCanvas as MobileDeckCanvas } from 'components/mobileDeckCanvas/DeckCanvas'
import { DeckLayoutContainer } from '../common/DeckLayoutContainer'

function EditDeckPage() {
  /**
   * Generates edit deck page
   *
   * @function
   *
   * @returns {object} - Edit Deck page
   */
  const [selected, setSelected] = useState([])
  const [materialSelected, setMaterialSelected] = useState('')
  const { deckId, equipmentId, deck, screen, deckRevision, setDeckRevision, history } = useCurrentDeckContext()

  usePrompt(loseDataWarning, history.history.length > 1)

  useEffect(() => {
    history.addHistory(deckRevision)
  }, [deckId])

  const [viewType, setViewType] = useState('3D')

  const switchViewType = () => {
    setViewType((currentViewType) => (currentViewType === '3D' ? '2D' : '3D'))
  }

  //Saving deck revsion
  function saveDeckRevision(newDeckRevision) {
    setDeckRevision(newDeckRevision)
    history.addHistory(newDeckRevision)
  }

  //Changing kit material
  function onSelectChange(data) {
    setSelected(data)
    setMaterialSelected('')
  }

  //Action on undo button
  function onUndo() {
    const data = history.undoHistory()
    if (data) {
      setDeckRevision(data)
    }
  }

  //Action on redo button
  function onRedo() {
    const data = history.redoHistory()
    if (data) {
      setDeckRevision(data)
    }
  }

  //logic for when a table row is clicked in the BOM Table.
  function onBomClick(kit, eligibleMaterialNumbers, panelType) {
    if (selected.length) {
      let change = false
      let panelsAffected = []
      let sideLinersAffected = []
      const panels = selected
        .filter((s) => s.Position)
        .sort((a, b) => {
          if (a.Position.Column > b.Position.Column) {
            return 1
          } else if (a.Position.Column < b.Position.Column) {
            return -1
          }
          if (a.Position.Row < b.Position.Row) {
            return -1
          } else if (a.Position.Row > b.Position.Row) {
            return 1
          } else {
            return 0
          }
        })
      const sideLiners = selected.filter((s) => s.Height).sort((a, b) => a.Height - b.Height)

      if (panelType === 'panel' || panelType === 'other') {
        panels.map((selectedPanel) => {
          if (
            (eligibleMaterialNumbers.includes(selectedPanel.MaterialNumber) ||
              !deckRevision.Kits.includes(selectedPanel.MaterialNumber)) &&
            !panelsAffected.find(
              (p) =>
                p.Position.Column === selectedPanel.Position.Column && p.Position.Row === selectedPanel.Position.Row
            )
          ) {
            const sColumn = new BigNumber(selectedPanel.Position.Column)
            const sRow = new BigNumber(selectedPanel.Position.Row)
            const sColumnEnd = sColumn.plus(kit.Size?.Width ?? 1).minus(1)
            const sRowEnd = sRow.plus(kit.Size?.Height ?? 1).minus(1)

            cleanupPanel(selectedPanel, sColumn, sRow, sColumnEnd, sRowEnd)

            //if a Panel is bigger than 1x1, then other panels must be found and removed to make space.
            if (kit.Size?.Height > 1 || kit.Size?.Width > 1) {
              let panelsToCleanup = []
              if (sColumnEnd.isLessThan(deckRevision.Size.Columns) && sRowEnd.isLessThan(deckRevision.Size.Rows)) {
                deckRevision.Panels = deckRevision.Panels.filter((panel) => {
                  const panelKit = deckRevision.Kits.find((a) => a.MaterialNumber === panel.MaterialNumber)
                  const panelColumnEnd = new BigNumber(panel.Position.Column).plus(panelKit?.Size?.Width ?? 1).minus(1)
                  const panelRowEnd = new BigNumber(panel.Position.Row).plus(panelKit?.Size?.Height ?? 1).minus(1)
                  const safeColumn =
                    sColumn.isGreaterThan(panelColumnEnd) || sColumnEnd.isLessThan(panel.Position.Column)
                  const safeRow = sRow.isGreaterThan(panelRowEnd) || sRowEnd.isLessThan(panel.Position.Row)
                  const isOriginal = sColumn.isEqualTo(panel.Position.Column) && sRow.isEqualTo(panel.Position.Row)
                  const isSafe = safeColumn || safeRow || isOriginal

                  if (!isSafe) {
                    panelsToCleanup.push(panel)
                  }

                  return isSafe
                })

                panelsAffected = panelsAffected.concat(panelsToCleanup)

                panelsToCleanup.map((p) => cleanupPanel(p, sColumn, sRow, sColumnEnd, sRowEnd))

                selectedPanel.MaterialNumber = kit.MaterialNumber
              }
            } else {
              selectedPanel.MaterialNumber = kit.MaterialNumber
            }
            change = true
          }
        })
      }

      if (panelType === 'sideliner' || (panelType === 'other' && !panels.length)) {
        sideLiners.map((selectedSideLiner) => {
          if (
            eligibleMaterialNumbers.includes(selectedSideLiner.MaterialNumber) ||
            !deckRevision.Kits.includes(selectedSideLiner.MaterialNumber)
          ) {
            if (
              !sideLinersAffected.find(
                (s) => s.StartPosition === selectedSideLiner.StartPosition && s.Side === selectedSideLiner.Side
              )
            ) {
              const kitHeight = new BigNumber(kit.Size?.Height ?? 1)
              const start = new BigNumber(selectedSideLiner.StartPosition)
              if (start.plus(kitHeight).isGreaterThan(deckRevision.Size.Rows)) {
                return
                //if SideLiner is not the same size as the previous, space needs to be made/added.
              } else if (kitHeight.isLessThan(selectedSideLiner.Height)) {
                const firstHeight = start.plus(kitHeight).toNumber()
                const lastHeight = start.plus(selectedSideLiner.Height).toNumber()
                for (let i = firstHeight; i < lastHeight; i++) {
                  deckRevision.SideLiners.push(
                    new SideLinersItems({
                      StartPosition: i,
                      Height: 1,
                      Side: selectedSideLiner.Side
                    })
                  )
                }
              } else if (kitHeight.isGreaterThan(selectedSideLiner.Height)) {
                const endPoint = start.plus(kitHeight)
                const sideLinersToRemove = deckRevision.SideLiners.filter(
                  (s) =>
                    start.isLessThan(s.StartPosition) &&
                    endPoint.isGreaterThan(s.StartPosition) &&
                    s.Side === selectedSideLiner.Side
                )

                sideLinersAffected = sideLinersAffected.concat(sideLinersToRemove)

                sideLinersToRemove.map((s) => {
                  const currentHeight = new BigNumber(s.Height)
                  const currentStart = new BigNumber(s.StartPosition)

                  deckRevision.SideLiners = deckRevision.SideLiners.filter(
                    (s2) => !(currentStart.isEqualTo(s2.StartPosition) && s.Side === s2.Side)
                  )

                  if (endPoint.isLessThan(currentStart.plus(currentHeight))) {
                    for (let i = 0; i < currentStart.plus(currentHeight).minus(endPoint).toNumber(); i++) {
                      deckRevision.SideLiners.push(
                        new SideLinersItems({
                          Side: s.Side,
                          StartPosition: endPoint.plus(i).toNumber(),
                          Height: 1
                        })
                      )
                    }
                  }
                })
              }
              change = true
              selectedSideLiner.MaterialNumber = kit.MaterialNumber
              selectedSideLiner.Height = kitHeight.toNumber()
            }
          }
        })
      }

      if (change) {
        const clone = structuredClone(deckRevision)
        setDeckRevision(clone)
        history.addHistory(clone)
        setSelected([])
      }
    } else {
      setSelected([])
      setMaterialSelected((previous) => {
        if (previous === kit.MaterialNumber) {
          return undefined
        }
        return kit.MaterialNumber
      })
    }
  }

  // Action on deleting a panel
  function cleanupPanel(panel, sColumn, sRow, sColumnEnd, sRowEnd) {
    const existingKit = deckRevision.Kits.find((a) => a.MaterialNumber === panel.MaterialNumber)
    const column = new BigNumber(panel.Position.Column)
    const row = new BigNumber(panel.Position.Row)

    if (existingKit && (existingKit.Size?.Height > 1 || existingKit.Size?.Width > 1)) {
      for (let i = column.toNumber(); i < column.plus(existingKit.Size.Width).toNumber(); i++) {
        for (let j = row.toNumber(); j < row.plus(existingKit.Size.Height).toNumber(); j++) {
          const safeColumn = sColumn?.isGreaterThan(i) || sColumnEnd?.isLessThan(i)
          const safeRow = sRow?.isGreaterThan(j) || sRowEnd?.isLessThan(j)

          if (safeColumn || safeRow) {
            deckRevision.Panels.push(
              new PanelsItems({
                MaintenanceState: '',
                MaterialNumber: null,
                Position: new PanelPositionItems({
                  Column: i,
                  Row: j
                })
              })
            )
          }
        }
      }
    }
  }

  function onPanelClick(selected) {
    if (selected) {
      setSelected([selected])
    } else {
      setSelected([])
    }
    setMaterialSelected('')
  }

  //only show Create buttons if Equipment exists.
  if (equipmentId && (!deckRevision || !Object.entries(deckRevision).length) && !deck) {
    return (
      <Grid item xs={12} sm={5} sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <CreateDeckButtons setDeckRevision={setDeckRevision} />
      </Grid>
    )
  }

  if (!screen || !deck) {
    return null
  }

  //if a Deck exists, but has no revision, show the Create Revision buttons.
  if (Object.entries(deck).length && (!deckRevision || !Object.entries(deckRevision).length)) {
    return (
      <>
        <Grid item xs={12} sm={5} sx={{ height: '5%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <CreateRevision deck={deck} />
        </Grid>
        <Typography>No Deck Revision found.</Typography>
      </>
    )
  }

  if (Object.entries(screen).length <= 0 || Object.entries(deck).length <= 0) {
    return <Typography>No Deck Revision found.</Typography>
  }

  return (
    <DeckLayoutContainer
      headerTitle="Edit Deck"
      buttons={<CreateRevision deck={deck} />}
      Deck={
        <>
          {viewType === '3D' ? (
            <DeckCanvas
              data={deckRevision}
              selected={selected}
              materialSelected={materialSelected}
              onSelectChange={onSelectChange}
              saveDeckRevision={saveDeckRevision}
              mode={DeckMode.edit}
              onUndo={onUndo}
              onRedo={onRedo}
              isHistoryEmpty={history.isEmpty}
              isHistoryAtEnd={history.isAtEnd}
              onSwitchView={switchViewType}
            />
          ) : (
            <MobileDeckCanvas
              data={deckRevision}
              selected={selected}
              materialSelected={materialSelected}
              onSelectChange={onSelectChange}
              saveDeckRevision={saveDeckRevision}
              mode={DeckMode.edit}
              onUndo={onUndo}
              onRedo={onRedo}
              isHistoryEmpty={history.isEmpty}
              isHistoryAtEnd={history.isAtEnd}
              onSwitchView={switchViewType}
              onPanelClick={onPanelClick}
            />
          )}
        </>
      }
      topRightElement={
        <KitItemForm
          key={materialSelected}
          deckRevision={deckRevision}
          setDeckRevision={saveDeckRevision}
          setMaterialSelected={setMaterialSelected}
          data={deckRevision.Kits.find((k) => k.MaterialNumber === materialSelected)}
          materialSelected={materialSelected}
        />
      }
      bottomRightElement={
        <BomTable
          data={deckRevision}
          selected={selected}
          materialSelected={materialSelected}
          onClick={onBomClick}
          canGenerateColor={!deckRevision.IsPublished}
          setDeckRevision={setDeckRevision}
        />
      }
    />
  )
}

export default EditDeckPage
