/**
 *
 * "Main module to generate deck canvas for mobile in 2D.
 * Only required for layout and Maintenance."
 *
 * @file   mobileDeckCanvas\DeckCanvas.js
 * @author Lateral
 * @since  2023
 */

import { Button, Fab, Grid, Paper, Typography, Zoom, IconButton, useTheme } from '@mui/material'
import React, { useState } from 'react'
import DeckModel from './components/DeckModel/DeckModel'
import { TransformWrapper, TransformComponent } from '@pronestor/react-zoom-pan-pinch'
import BigNumber from 'bignumber.js'
import { useMaintenance } from 'hooks'
import { DeckMode } from 'common/deckMode'
import MaintenanceSelector from 'components/maintenanceSelector/MaintenanceSelector'

import { ReactComponent as UndoSvg } from 'assets/icons/Undo.svg'
import { ReactComponent as RedoSvg } from 'assets/icons/Redo.svg'

export function DeckCanvas({
  data,
  maintenance,
  onPanelClick,
  onMaintenanceTypeClick,
  selected,
  materialSelected,
  mouseCanvasPosition,
  onWearSubmit,
  awaitingActionId,
  cancelAwaitingAction,
  mode,
  onSwitchView,
  heatMapData,
  onUndo,
  isHistoryEmpty,
  onRedo,
  isHistoryAtEnd,
  saveDeckRevision,
  onSelectChange
}) {
  /**
   * Generates 2D deck canvas
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {} onMaintenanceTypeClick - Action to click on Maintenance type in Maintenance mode
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} materialSelected - Selected sideliner material number
   * @param {object} mouseCanvasPosition - x and y coordinates of pointer on canvas
   * @param {} onMaintenanceTypeClick - Action on changing panel/sideliner selection
   * @param {} onWearSubmit - Action on submiting wear in Maintenance mode
   * @param {number} awaitingActionId - id of await action for asynch operation
   * @param {number} cancelAwaitingAction - Id of await action when click cancel in maintenance mode
   * @param {string} mode - Current mode of deck layout
   * @returns {object} - React element of 3D deck canvas
   */
  const initialScale = Math.min(new BigNumber(1).dividedBy(data.Size.Rows).times(25).toNumber(), 1)
  const { actions } = useMaintenance()
  const canvasHeight = new BigNumber(window.innerHeight).times(0.65)
  const theme = useTheme()
  const [isInserting, setIsInserting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const hasUsedKits =
    data?.Panels.filter((p) => p.MaterialNumber !== undefined && p.MaterialNumber !== null)?.length > 0 ||
    data?.SideLiners.filter((s) => s.MaterialNumber !== undefined && s.MaterialNumber !== null)?.length > 0

  const canShowDeleteButton = mode === DeckMode.edit && saveDeckRevision && !hasUsedKits

  function onCancel() {
    setIsInserting(false)
    setIsDeleting(false)
  }

  return (
    <Paper
      elevation={8}
      sx={{
        marginTop: '0.1em',
        width: '100%',
        height: `${canvasHeight}px`,
        backgroundColor: 'primary.dark',
        backgroundImage: 'none'
      }}>
      <Grid
        container
        flexDirection={onUndo || onRedo || canShowDeleteButton ? 'row' : 'row-reverse'}
        justifyContent="space-between"
        padding="1em">
        {(onUndo || onRedo) && (
          <Grid item direction="row">
            {onUndo && (
              <IconButton
                onClick={onUndo}
                disabled={isHistoryEmpty}
                sx={{
                  '& *': {
                    opacity: isHistoryEmpty ? '50%' : '100%',
                    width: '0.5em'
                  }
                }}>
                <UndoSvg fill={`${theme.palette.secondary.main}`} />
              </IconButton>
            )}
            {onRedo && (
              <IconButton
                onClick={onRedo}
                disabled={isHistoryAtEnd}
                sx={{
                  marginRight: '1em',
                  '& *': {
                    opacity: isHistoryAtEnd ? '50%' : '100%',
                    width: '0.5em'
                  }
                }}>
                <RedoSvg fill={`${theme.palette.secondary.main}`} />
              </IconButton>
            )}
          </Grid>
        )}

        {/* buttons for inserting/deleting columns/rows */}
        {canShowDeleteButton ? (
          <Grid item sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isInserting || isDeleting ? (
              <Button variant="outlined" onClick={() => onCancel()} sx={{ color: 'text.secondary' }}>
                Cancel
              </Button>
            ) : (
              <>
                <Button variant="outlined" onClick={() => setIsInserting(true)}>
                  Insert
                </Button>
                <Typography marginLeft="1em" marginRight="1em" component="p" color={'text.secondary'}>
                  |
                </Typography>
                <Button variant="outlined" onClick={() => setIsDeleting(true)} sx={{ color: 'text.secondary' }}>
                  Delete
                </Button>
              </>
            )}
          </Grid>
        ) : null}
        {!!onSwitchView && (
          <Grid item>
            <Fab onClick={onSwitchView} variant="extended" color="secondary" size="small">
              3D view
            </Fab>
          </Grid>
        )}
      </Grid>

      <TransformWrapper centerOnInit={true} limitToBounds={false} initialScale={initialScale} minScale={initialScale}>
        <Zoom in={awaitingActionId === actions.Swap.id || awaitingActionId === actions.Relocate.id}>
          <Grid
            container
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
              marginLeft: '-1em',
              width: '100vw',
              padding: '1em',
              position: 'absolute',
              zIndex: '100'
            }}>
            <Grid item xs={10}>
              <Typography>
                {awaitingActionId === actions.Swap.id
                  ? 'Select panel to swap with'
                  : 'Where are you moving this panel to?'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button color="secondary" onClick={cancelAwaitingAction}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Zoom>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} sx={{ height: '100%' }}>
            <TransformComponent
              contentStyle={{ display: 'block', width: '100%', height: '100%' }}
              wrapperStyle={{ width: '100%', height: '100%' }}>
              <DeckModel
                data={data}
                maintenance={maintenance}
                onPanelClick={onPanelClick}
                selected={selected}
                materialSelected={materialSelected}
                mode={mode}
                heatMapData={heatMapData}
                onSelectChange={onSelectChange}
              />
            </TransformComponent>
          </Grid>
          {mode === DeckMode.maintenance && mouseCanvasPosition ? (
            <MaintenanceSelector
              isMobile
              mouseCanvasPosition={mouseCanvasPosition}
              onMaintenanceTypeClick={onMaintenanceTypeClick}
              selected={selected}
              maintenance={maintenance}
              deckRevision={data}
              onWearSubmit={onWearSubmit}
            />
          ) : null}
        </Grid>
      </TransformWrapper>
    </Paper>
  )
}

export default DeckCanvas
