/**
 *
 * "Post-Shut Report page for viewing and interact Post-Shut
 * Reprot Form and export Report in a .DocX file."
 *
 * @file   PartsPage.js
 * @author Lateral
 * @since  2023
 */
import { Box, Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import { useCurrentUser, useMaintenance } from 'hooks'
import dayjs from 'dayjs'
import PostShutForm from './components/postShut/PostShutForm'
import {
  companyName,
  crewDetailsName,
  introductionName,
  recentJobsName,
  recommendationsName,
  safetyIssuesName,
  scopeOfWorkName,
  shutdownCommentsName,
  shutEndDateName,
  shutStartDateName,
  siteContactName,
  siteName,
  siteRepresentativeName,
  titleName
} from './common/formNames'
import { saveAs } from 'file-saver'
import { toPng } from 'html-to-image'
import { createReport } from 'docx-templates'
import BigNumber from 'bignumber.js'
import { Storage } from 'aws-amplify'
import { CrewDetailsItems } from 'models'
import { createReport as createReportModel } from './common/data'
import { reportTypes } from './common/reportTypes'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import { dateFormat } from 'common/dates'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { blobToBase64 } from 'common/converters'
import { getKitTable } from './common/generation'

function PostShutPage() {
  /**
   * Generates Post-shut Report page with Post-Shut
   * Report Form to view on desktop sized screen
   *
   * @function
   *
   * @returns {object} - Post-Shut Report page
   */
  const theme = useTheme()
  const { actions, getAction, wearColours } = useMaintenance()
  const { database } = useContext(CurrentDeckContext)
  const { existingReport, setExistingReport } = useContext(ReportingContext)
  const { getTransactionMetaData } = useCurrentUser()

  //generate the DocX
  async function onSubmit(form) {
    const customer = database.customers.find((c) => c.id === form[companyName])
    const site = database.sites.find((s) => s.id === form[siteName])

    const legendSize = 0.5
    const photoSize = 8
    const base64Prepend = 'data:image/png;base64,'
    const base64OctetPrepend = 'data:binary/octet-stream;base64,'
    const title = form[titleName] ? form[titleName] : customer.Name

    let model = existingReport?.Type === reportTypes.PostShut ? structuredClone(existingReport) : {}
    model.Type = reportTypes.PostShut
    model.CustomerId = customer.id
    model.Company = customer.Name
    model.Title = title
    model.SiteId = site.id
    model.Site = site.Name
    model.DateOfVisit = dayjs(form[shutStartDateName]).toISOString()
    model.EndDate = dayjs(form[shutEndDateName]).toISOString()
    model.SiteContact = form[siteContactName]
    model.SiteRepresentative = form[siteRepresentativeName]
    model.Introduction = form[introductionName]
    model.ScopeOfWork = form[scopeOfWorkName]
    model.SafetyIssues = form[safetyIssuesName]
    model.CrewDetails = form[crewDetailsName].map(
      (c) =>
        new CrewDetailsItems({
          Shift: c.Shift,
          Name: c.Name,
          Position: c.Position
        })
    )
    model.ShutdownComments = form[shutdownCommentsName]
    model.Recommendations = form[recommendationsName]
    model.Selections = form[recentJobsName].filter((j) => j.Include).map((j) => j.HistoryId)

    if (!model.Revision) {
      model.Revision = 0
    }
    if (existingReport && JSON.stringify(existingReport) !== JSON.stringify(model)) {
      model.Revision += 1
    }

    //create/update the report, but only if something changed.
    if (!existingReport || model.Revision !== existingReport?.Revision) {
      const result = await createReportModel(model, getTransactionMetaData())
      setExistingReport(result)
    }

    //gather data
    let data = {
      Company: customer.Name,
      Site: site.Name,
      Title: title,
      DateOfVisit: dayjs(form[shutStartDateName]).format(dateFormat),
      SiteContact: form[siteContactName],
      SiteRepresentative: form[siteRepresentativeName],
      Introduction: form[introductionName],
      ScopeOfWork: form[scopeOfWorkName],
      SafetyIssues: form[safetyIssuesName],
      CrewDetails: form[crewDetailsName],
      ShutdownComments: form[shutdownCommentsName],
      Recommendations: form[recommendationsName],
      Inspections: await Promise.all(
        form[recentJobsName]
          .filter((f) => f.Include)
          .map(async (job) => {
            const history = database.deckRevisionHistories.find((d) => d.id === job.HistoryId)
            const deckRevision = database.deckRevisions.find((d) => d.id === history.RevisionId)
            const deck = database.decks.find((d) => d.id === deckRevision.DeckId)
            const location = database.locations.find((l) => l.id === deck.LocationId)
            const screen = database.screens.find((s) => s.id === deck.ScreenId)

            //show, render to PNG, then hide the hidden canvases
            job.htmlElement.hidden = false
            const postLayout = await toPng(job.htmlElement)
            job.htmlElement.hidden = true

            job.defaultElement.hidden = false
            const defaultLayout = await toPng(job.defaultElement)
            job.defaultElement.hidden = true

            let preLayout = ''

            if (job.preElement) {
              job.preElement.hidden = false
              preLayout = await toPng(job.preElement)
              job.preElement.hidden = true
            }

            let itemList = []
            history.Details.forEach((detail) => {
              const materialNumber = detail.Panel?.MaterialNumber ?? detail.SideLiner?.MaterialNumber
              const kit = deckRevision.Kits.find((k) => k.MaterialNumber === materialNumber)

              if (
                materialNumber &&
                detail.HistoryAction !== actions.NoChange.id &&
                !itemList.find((i) => i.MaterialNumber === materialNumber && i.HistoryAction === detail.HistoryAction)
              ) {
                const similarDetails = history.Details.filter(
                  (d) =>
                    (d.Panel?.MaterialNumber === materialNumber || d.SideLiner?.MaterialNumber === materialNumber) &&
                    d.HistoryAction === detail.HistoryAction
                )

                const action = getAction(detail.HistoryAction)

                itemList.push({
                  MaterialNumber: kit.MaterialNumber ?? '',
                  SchenckNumber: screen.SchenckSerial,
                  HistoryAction: detail.HistoryAction,
                  HistoryState: action.state,
                  PartNumber: kit.PartNumber,
                  Description: kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber,
                  Quantity: similarDetails.length
                })
              }
            })

            //gather photos
            let photoList = []
            if (history.Photos) {
              //we have to add photos 2 at a time, due to alignment issues in docx files.
              for (let i = 0; i < history.Photos.length; i += 2) {
                const photoKey1 = history.Photos[i]
                const photo1Model = await getPhotoModel(photoKey1, photoSize, base64OctetPrepend)
                let photo2Model = null

                if (i + 1 < history.Photos.length) {
                  const photoKey2 = history.Photos[i + 1]
                  photo2Model = await getPhotoModel(photoKey2, photoSize, base64OctetPrepend)
                }

                photoList.push({
                  Photo1: photo1Model,
                  Photo2: photo2Model
                })
              }
            }

            return {
              Name: `${location.Name} - ${screen.Name} - ${deck.DeckHeader.EquipmentId} - ${deck.DeckHeader.DeckLevel}`,
              Location: location.Name,
              PostLayout: {
                data: postLayout.slice(base64Prepend.length),
                extension: '.png',
                width: 9,
                height: Math.max(new BigNumber(deck.DeckHeader.DeckSize.Rows).dividedBy(2).toNumber(), 9)
              },
              PreLayoutExists: preLayout.length > 0,
              PreLayout: {
                data: preLayout?.slice(base64Prepend.length),
                extension: '.png',
                width: 9,
                height: Math.max(new BigNumber(deck.DeckHeader.DeckSize.Rows).dividedBy(2).toNumber(), 9)
              },
              DefaultLayout: {
                data: defaultLayout?.slice(base64Prepend.length),
                extension: '.png',
                width: 9,
                height: Math.max(new BigNumber(deck.DeckHeader.DeckSize.Rows).dividedBy(2).toNumber(), 9)
              },
              Equipment: deck.DeckHeader.EquipmentId,
              ScreenNumber: screen.Name,
              SchenckNumber: screen.SchenckSerial,
              MaterialNumber: deck.DeckHeader.MaterialNumber,
              Tonnage: history.Tonnage,
              Summary: history.Description,
              PreServiceComments: history.PreServiceComments,
              PostServiceComments: history.PostServiceComments,
              DeckRailsComments: history.DeckRailsComments,
              CrossbeamComments: history.CrossbeamComments,
              Recommendations: history.Recommendations,
              ItemsChanged: itemList,
              KitTable: getKitTable(deckRevision),
              Photos: photoList
            }
          })
      ),
      Legend: {
        Replace: {
          data: actions.Replace.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Rotate: {
          data: actions.Rotate.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Swap: {
          data: actions.Swap.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        RelocateFrom: {
          data: actions.Relocate.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        RelocateTo: {
          data: actions.RelocateTo.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear0: {
          data: wearColours.zero.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear20: {
          data: wearColours.twenty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear40: {
          data: wearColours.forty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear60: {
          data: wearColours.sixty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        },
        Wear80: {
          data: wearColours.eighty.img.slice(base64Prepend.length),
          extension: '.png',
          width: legendSize,
          height: legendSize
        }
      },
      ItemsSummary: []
    }

    //generate item summary
    data.Inspections.forEach((inspection) => {
      inspection.ItemsChanged.forEach((item) => {
        if (item.HistoryAction === actions.Replace.id || item.HistoryAction === actions.Relocate.id) {
          const existing = data.ItemsSummary.find(
            (i) => i.MaterialNumber === item.MaterialNumber && i.SchenckNumber === item.SchenckNumber
          )
          if (existing) {
            existing.Quantity = new BigNumber(existing.Quantity).plus(item.Quantity).toNumber()
          } else {
            data.ItemsSummary.push(structuredClone(item))
          }
        }
      })
    })

    //generate DocX
    const template = await Storage.get('postshut.docx', { download: true })
    const report = await createReport({ template: template.Body, data: data, cmdDelimiter: ['{#', '#}'] })

    const reportBlob = new Blob([report], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    })

    saveAs(reportBlob, `${title.replace(/[^a-z0-9]/gi, '_')}.docx`)
  }

  async function getPhotoModel(photoKey, photoSize, base64OctetPrepend) {
    const photo = await Storage.get(photoKey.Key, { download: true })
    const body = await blobToBase64(photo.Body)
    return {
      Photo: {
        data: body.slice(base64OctetPrepend.length),
        extension: '.png',
        width: photoSize,
        height: new BigNumber(photoSize).times(photoKey.Height).dividedBy(photoKey.Width).toNumber()
      },
      Caption: photoKey.Caption
    }
  }

  return (
    <Box sx={{ height: '80%', width: '50%' }}>
      <Typography variant="h4" component="h4" sx={{ marginTop: '1em' }}>
        Post-Shut Report
      </Typography>
      <Paper
        elevation={6}
        sx={{
          backgroundImage: 'none',
          height: '95%',
          marginTop: '0.5em',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <PostShutForm
          data={existingReport}
          customers={database.customers}
          sites={database.sites}
          locations={database.locations}
          decks={database.decks}
          deckRevisions={database.deckRevisions}
          deckRevisionHistories={database.deckRevisionHistories}
          onSubmit={onSubmit}
        />
      </Paper>
    </Box>
  )
}
export default PostShutPage
