/**
 *
 * Maintenance page to view in desktop sized screen with 3D deck canvas
 *
 * @file   MaintenancePage.js
 * @author Lateral
 * @since  2023
 */
import { useNotification } from 'components'
import { Button, Typography, Stack, useTheme, Box } from '@mui/material'
import React from 'react'
import { useCurrentUser, usePrompt } from 'hooks'
import {
  useCurrentDeckContext,
  defaultMaintenanceData,
  ONE_WEEK_MILLIS
} from 'components/currentDeckContext/CurrentDeckContext'
import { createDeckRevisionHistory, updateDeckRevisionHistory } from '../common/historyData'
import CreateHistory from './components/CreateHistory/CreateHistory'
import dayjs from 'dayjs'
import { loseDataWarning } from 'common/messaging'
import { Storage } from 'aws-amplify'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { addWearAppAction } from 'pages/common/maintenance/logic'

/*
 *Page for all things Maintenance. Shows the 3D canvas and SummaryForm.
 */
function MaintenancePage() {
  /**
   * Generates Maintenance page with 3D cube panels with maintenance components
   *
   * @function
   *
   * @returns {object} - Maintenance page
   */
  const { getUserName, getTransactionMetaData } = useCurrentUser()
  const {
    deck,
    screen,
    memoizedDeckRevision: deckRevision,
    history,
    maintenanceData,
    inMaintenanceMode,
    setInMaintenanceMode,
    setMaintenanceData,
    database,
    actions,
    wearAnalysisResults,
    nonArchivedHistories,
    maintenanceFrequency
  } = useCurrentDeckContext()

  const { notify } = useNotification()
  const { state, pathname } = useLocation()
  const theme = useTheme()

  //TODO: ask for when it's appropriate to navigate
  usePrompt(loseDataWarning, false)

  // Start deck maintenance
  function onStart() {
    const clone = structuredClone(maintenanceData)
    clone.deckRevisionHistory.Details = []
    if (!clone.deckRevisionHistory.DatePerformed) {
      clone.deckRevisionHistory.DatePerformed = dayjs().toISOString()
    }
    if (!clone.deckRevisionHistory.NextShutDownDate) {
      clone.deckRevisionHistory.NextShutDownDate = dayjs(nonArchivedHistories.histories[0]?.DatePerformed || dayjs())
        .add((maintenanceFrequency || 0) * ONE_WEEK_MILLIS, 'milliseconds')
        .toISOString()
    }

    setInMaintenanceMode(true)
    setMaintenanceData(clone)
    history.addHistory(clone)
  }

  // Action on 'Completing Maintenance' button
  const isWearAppFlow = pathname.includes('wearapp')

  async function onComplete(data) {
    let clone = structuredClone(maintenanceData)
    // get all 'unsaved' wearapp results
    const wearAppResults = clone.deckRevisionHistory.Details.filter(
      ({ HistoryAction }) => HistoryAction === actions.ViewWearAppResult.id
    )

    for (const result of wearAppResults) {
      const wearAppResult = wearAnalysisResults[result.extraData.uuid]
      clone = addWearAppAction(
        wearAppResult,
        clone,
        result.Panel,
        wearAppResult.replacePanel?.replacePanel === 'replace' ? actions.Replace.id : actions.NoChange.id,
        deckRevision
      )
    }
    clone.deckRevisionHistory.RevisionId = deckRevision.id
    clone.deckRevisionHistory.RevisionNumber = 1
    clone.deckRevisionHistory.HistoryType = 'Maintenance'

    clone.deckRevisionHistory.CustomerId = deck.CustomerId
    clone.deckRevisionHistory.SiteId = deck.SiteId

    let photoKeys = []

    for (let i = 0; i < clone.photos?.length; i++) {
      const photo = clone.photos[i]

      //if the photo already has a key, it was uploaded beforehand. No need to upload again
      if (photo.key) {
        photoKeys.push({ Key: photo.key, Caption: photo.caption, Height: photo.height, Width: photo.width })
      } else {
        const fileName = `${deckRevision.id}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}-${i}.png`

        try {
          const result = await Storage.put(fileName, photo.file, {
            metadata: { original: 'true' }
          })
          photoKeys.push({ Key: result.key, Caption: photo.caption, Height: photo.height, Width: photo.width })
        } catch (err) {
          console.error(err)
          notify('Could not upload photos!')
          return
        }
      }
    }
    clone.deckRevisionHistory.Details = clone.deckRevisionHistory.Details.map((data) => {
      if (data.extraData) {
        delete data.extraData
      }
      return data
    })
    clone.deckRevisionHistory.Photos = photoKeys

    if (state?.isEditingOld) {
      if (!clone.deckRevisionHistory.Updates) {
        clone.deckRevisionHistory.Updates = []
      }
      clone.deckRevisionHistory.Updates.push({
        User: getUserName(),
        Date: dayjs().toISOString(),
        Description: data.Description
      })
      const existing = database.deckRevisionHistories.find((d) => d.id === clone.deckRevisionHistory.id)

      if (existing) {
        updateDeckRevisionHistory(existing, clone.deckRevisionHistory, getTransactionMetaData())
        notify('Successfully updated Maintenance.')
      } else {
        notify('Maintenance not found!')
      }
    } else {
      clone.deckRevisionHistory.CreatedBy = getUserName()
      clone.deckRevisionHistory.ShortDescription = data.Description
      createDeckRevisionHistory(clone.deckRevisionHistory, getTransactionMetaData())
      notify('Successfully created Maintenance.')
    }
    setInMaintenanceMode(false)

    setMaintenanceData(defaultMaintenanceData)
    history.clearHistory()
  }

  // Cancelling maintenance
  function onCancel() {
    setInMaintenanceMode(false)
    notify('Cancelled Maintenance.')
    setMaintenanceData(defaultMaintenanceData)
  }

  if (!screen || !deck || !deckRevision) {
    return null
  }

  if (Object.entries(screen).length <= 0 || Object.entries(deck).length <= 0) {
    return <Typography>No Deck Revision found.</Typography>
  }

  const Container = ({ children }) =>
    isWearAppFlow ? (
      children
    ) : (
      <Box
        boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1);"
        position="sticky"
        padding=".4em"
        top={62}
        zIndex={1200}
        bgcolor={theme.palette.supporting.dark}>
        {children}
      </Box>
    )

  return (
    <>
      <Container>
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Typography variant={isWearAppFlow ? 'h4' : 'h1'}>Maintenance</Typography>
          {inMaintenanceMode ? (
            !isWearAppFlow && <CreateHistory onComplete={onComplete} onCancel={onCancel} />
          ) : (
            <Button variant="contained" color="secondary" onClick={onStart}>
              Start Maintenance
            </Button>
          )}
        </Stack>
      </Container>

      <Outlet />
    </>
  )
}

export default MaintenancePage
