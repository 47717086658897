/**
 *
 * "Main module to generate 3D deck canvas from the components"
 *
 * Note: The 3D canvas. This uses react-three-fiber,
 * so take care with adding unnecessary data in.
 * The more complicated, the more it has to redraw the 3D scene.
 *
 * @file   DeckCanvas.js
 * @author Lateral
 * @since  2023
 */
import { Canvas } from '@react-three/fiber'
import { Button, Fab, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState, Suspense } from 'react'
import deg2Rad from './common/angles'
import DeckModel from './components/DeckModel/DeckModel'
import DeckLabels from './components/DeckLabels/DeckLabels'
import Inserter from './components/Inserter/Inserter'
import MaintenanceLegend from './components/MaintenanceLegend/MaintenanceLegend'
import { DeckMode } from 'common/deckMode'
import MaintenanceSelector from '../maintenanceSelector/MaintenanceSelector'
import DeckGridCoordinates from './components/DeckGridCoordinates/DeckGridCoordinates'
import { useMaintenance } from 'hooks'
import { ReactComponent as UndoSvg } from 'assets/icons/Undo.svg'
import { ReactComponent as RedoSvg } from 'assets/icons/Redo.svg'
import { parsedRevisionNumberWithSubLetter } from 'common/revisionNumber'

const rotation = [deg2Rad(-40), deg2Rad(32), deg2Rad(24)]

export function DeckCanvas(props) {
  /**
   * Provide a react element of deck canvas
   *
   * @function
   * @param {object} props - Deck properties. Check InnerDeckCanvas() for details.
   *
   * @returns {object} - Deck canvas
   */
  return (
    <Suspense fallback={<Typography sx={{ p: '16px' }}>Loading Deck Revision...</Typography>}>
      <InnerDeckCanvas {...props}></InnerDeckCanvas>
    </Suspense>
  )
}

export function InnerDeckCanvas({
  data,
  maintenance,
  heatMapData,
  onPanelClick,
  onMaintenanceTypeClick,
  selected,
  materialSelected,
  mouseCanvasPosition,
  onSelectChange,
  onWearSubmit,
  saveDeckRevision,
  awaitingActionId,
  cancelAwaitingAction,
  mode,
  onUndo,
  onRedo,
  onLoadTrial,
  isHistoryEmpty = true,
  isHistoryAtEnd = true,
  onSwitchView,
  showLegend = true
}) {
  /**
   * Generates 3D deck canvas
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {object} heatMapData - Heat map data of all anels and sideliner
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {} onMaintenanceTypeClick - Action to click on Maintenance type in Maintenance mode
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} materialSelected - Selected sideliner material number
   * @param {object} mouseCanvasPosition - x and y coordinates of pointer on canvas
   * @param {} onMaintenanceTypeClick - Action on changing panel/sideliner selection
   * @param {} onWearSubmit - Action on submiting wear in Maintenance mode
   * @param {} saveDeckRevision - Function to save deckrivision hystory
   * @param {number} awaitingActionId - id of await action for asynch operation
   * @param {number} cancelAwaitingAction - Id of await action when click cancel in maintenance mode
   * @param {string} mode - Current mode of deck layout
   * @param {} onUndo - Aaction on clicking undo button in maintenance mode
   * @param {} onRedo - Aaction on clicking redo button in maintenance mode
   * @param {} onLoadTrial - Aaction on clicking Load Trial button
   * @param {boolean} isHistoryEmpty - True if the history of deck revision is empty else false. Default: True
   * @param {boolean} isHistoryAtEnd - True if the current history is the last of revison histories
   *
   * @returns {object} - React element of 3D deck canvas
   */

  const [isInserting, setIsInserting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  //note: theming cannot be passed down the canvas with useTheme, so we have to pass it as a prop
  const theme = useTheme()
  const hasUsedKits =
    data?.Panels?.filter((p) => p.MaterialNumber !== undefined && p.MaterialNumber !== null)?.length > 0 ||
    data?.SideLiners?.filter((s) => s.MaterialNumber !== undefined && s.MaterialNumber !== null)?.length > 0

  const { actions } = useMaintenance()

  function onCancel() {
    setIsInserting(false)
    setIsDeleting(false)
  }

  if (Object.keys(data).length === 0) {
    return null
  }

  return (
    <Paper
      elevation={8}
      sx={{ height: '100%', p: '16px', backgroundImage: 'none', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Grid container sx={{ height: '5%' }}>
        {awaitingActionId === actions.Swap.id || awaitingActionId === actions.Relocate.id ? (
          <Grid container sx={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'black', width: '100%' }}>
            <Grid item xs={10}>
              <Typography>
                {awaitingActionId === actions.Swap.id
                  ? 'Select panel to swap with'
                  : 'Where are you moving this panel to?'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button color="secondary" onClick={cancelAwaitingAction}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} flexDirection="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row">
                {onUndo && (
                  <IconButton
                    onClick={onUndo}
                    disabled={isHistoryEmpty}
                    sx={{
                      '& *': {
                        opacity: isHistoryEmpty ? '50%' : '100%',
                        width: '0.5em'
                      }
                    }}>
                    <UndoSvg fill={`${theme.palette.secondary.main}`} />
                  </IconButton>
                )}
                {onRedo && (
                  <IconButton
                    onClick={onRedo}
                    disabled={isHistoryAtEnd}
                    sx={{
                      marginRight: '1em',
                      '& *': {
                        opacity: isHistoryAtEnd ? '50%' : '100%',
                        width: '0.5em'
                      }
                    }}>
                    <RedoSvg fill={`${theme.palette.secondary.main}`} />
                  </IconButton>
                )}
              </Stack>

              <Typography className="deck-canvas-info" component="p">
                {`Revision: ${parsedRevisionNumberWithSubLetter(data)} ${data.IsTrial ? '(TRIAL)' : ''} ${
                  !data.IsPublished ? '(UNPUBLISHED)' : ''
                }`}
              </Typography>
              {onLoadTrial ? (
                <Button variant="text" onClick={onLoadTrial} sx={{ color: `${theme.palette.supporting.highlight}` }}>
                  LOAD TRIAL
                </Button>
              ) : null}
            </Stack>
            {/* buttons for inserting/deleting columns/rows */}
            {mode === DeckMode.edit && saveDeckRevision && !hasUsedKits ? (
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                {isInserting || isDeleting ? (
                  <Button onClick={() => onCancel()} sx={{ color: 'text.secondary' }}>
                    Cancel
                  </Button>
                ) : (
                  <>
                    <Button onClick={() => setIsInserting(true)} sx={{ color: 'text.secondary' }}>
                      Insert
                    </Button>
                    <Typography marginLeft="1em" marginRight="1em" component="p" color={'text.secondary'}>
                      |
                    </Typography>
                    <Button onClick={() => setIsDeleting(true)} sx={{ color: 'text.secondary' }}>
                      Delete
                    </Button>
                  </>
                )}
              </Grid>
            ) : null}
            {onSwitchView && (
              <Grid item>
                <Fab onClick={onSwitchView} variant="extended" color="secondary" size="small">
                  2D view
                </Fab>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {/* The canvas. 3D from here on. */}
      <Canvas dpr={[1, 2]} flat orthographic camera={{ zoom: 16, position: [0, 0, 100] }} frameloop="demand">
        <ambientLight intensity={0.5} />
        <directionalLight intensity={1.2} />

        {!isInserting && !isDeleting ? (
          <DeckLabels deckHeight={data.Size?.Rows} deckWidth={data.Size?.Columns} rotation={rotation} deckMode={mode} />
        ) : null}

        <DeckModel
          data={data}
          maintenance={maintenance}
          heatMapData={heatMapData}
          onPanelClick={onPanelClick}
          rotation={rotation}
          selected={selected}
          materialSelected={materialSelected}
          onSelectChange={onSelectChange}
          theme={theme}
          deckMode={mode}
        />

        {(isInserting || isDeleting) && saveDeckRevision ? (
          <Inserter
            data={data}
            theme={theme}
            rotation={rotation}
            onComplete={onCancel}
            isDeleting={isDeleting}
            isInserting={isInserting}
            saveDeckRevision={saveDeckRevision}
          />
        ) : null}

        <DeckGridCoordinates data={data} rotation={rotation} />
      </Canvas>

      {/* The Maintenance legend and radial menu */}
      {mode === DeckMode.maintenance && showLegend ? <MaintenanceLegend awaitingActionId={awaitingActionId} /> : null}
      {mode === DeckMode.maintenance && mouseCanvasPosition ? (
        <MaintenanceSelector
          isMobile={false}
          mouseCanvasPosition={mouseCanvasPosition}
          onMaintenanceTypeClick={onMaintenanceTypeClick}
          selected={selected}
          maintenance={maintenance}
          deckRevision={data}
          onWearSubmit={onWearSubmit}
        />
      ) : null}
    </Paper>
  )
}

export default DeckCanvas
